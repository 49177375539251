body {
    .select2-container--default {
        .select2-selection--multiple {
            .select2-selection__rendered {
                .select2-selection__choice {
                    width: auto;
                }
            }
        }
    }

    /** Multi select select2 dropdown with checkbox */
    .select2-container--default {
        min-width: 100% !important;

        .company_dashboard_selection {
            .select2-search--inline {
                .select2-search__field {
                    width: 90% !important;
                    margin: 8px 15px 0;
                }
            }

            &.select2-selection--multiple {
                border-radius: 4px;
                margin-bottom: 10px;
                border-width: 2px;
            }
        }

        .select2-container--focus {
            .company_dashboard_selection {
                &.select2-selection--multiple {
                    border-color: #f77750;
                    border-width: 2px;
                }
            }
        }

        .company_dashboard_dropdown {
            &.select2-dropdown--below {
                border-radius: 6px;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
            }

            .select2-results {
                .select2-results__options {
                    .select2-results__option {
                        padding-right: 20px;
                        vertical-align: middle;

                        &:before {
                            content: "";
                            display: inline-block;
                            position: relative;
                            height: 20px;
                            width: 20px;
                            border: 2px solid #e9e9e9;
                            border-radius: 4px;
                            background-color: #fff;
                            margin-right: 20px;
                            vertical-align: middle;
                        }


                        &.select2-results__option--selected {
                            background-color: #fff;

                            &:before {
                                font-family: "Font Awesome 6 Regular", Arial, Helvetica, Sans-serif;
                                content: "\f00c";
                                color: #fff;
                                background-color: #f77750;
                                border: 0;
                                display: inline-block;
                                padding-left: 4px;
                            }
                        }

                        &.select2-results__option--highlighted {
                            background-color: #eaeaeb;
                            color: #272727;
                        }
                    }
                }

                .apply_filters_container {
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }
    }

    &.profile-content-body {
        #app {
            .profile-main {
                .dashboard {

                    /* Ellipsis Text */
                    .ellipsis-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        }

                    /* Dashboard Header */
                    .dashboard-header {
                        margin-bottom: 20px;
                        padding: 15px;
                        background-color: $color-white;

                        @include rounded(4px);

                        > .row {
                            justify-content: center;
                            align-items: center;

                            .dashboard-header-greeting {
                                font-size: 2.00rem;
                                }
                            .dashboard-greeting-info {}
                        }
                    }

                    /* Company Dashboard */
                    &.company_dashboard {

                        .main-dashboard-form {

                        }
                    }

                    /* Summary Dashboard */
                    > .container {
                        > .row {
                            > .col-12 {
                                > .main-dashboard-form {
                                    padding-right: 5px;
                                    padding-left: 5px;

                                    #company_dashboard_metrics {
                                        padding: 0;
                                        margin: 0;
                                    }

                                    .widgets-row {
                                        margin: 0 -10px;

                                        .col-1,.col-2,.col-3,.col-4,
                                        .col-5,.col-6,.col-7,.col-8,
                                        .col-9,.col-10,.col-11,.col-12 {
                                            padding-right: 10px;
                                            padding-left: 10px;
                                            }

                                        .company_dashboard_filters {
                                            .filter-container {
                                                ul {
                                                    li {
                                                        width: 33%;

                                                        &.apply_filter_pending {
                                                            span.selection {
                                                                span.select2-selection {
                                                                    border: 1px solid #e4002b;
                                                                    }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    /* Dashboard Widgets */
                                    .dashboard-widget {

                                        .no-padding { padding: 0 !important; }

                                        .dashboard-widget-inner {
                                            margin: 10px 0;
                                            border: 1px solid $color-lightgray-3;
                                            background-color: $color-white;

                                            @include rounded(4px);

                                            h4 {
                                                position: relative;
                                                margin: 0;
                                                padding: 0;
                                                display: block;
                                                color: $color-darkgray;
                                                font-size: 1.15rem;
                                                line-height: 1.65rem;
                                                border-bottom: 1px solid $color-lightgray-3;

                                                > a {
                                                    &.dashboard-widget-action-icon {
                                                        position: absolute;
                                                        top: 10px;
                                                        right: 15px;
                                                        z-index: 2;
                                                        color: $color-darkgray;

                                                        @include transition(color 0.10s ease-in-out);

                                                        /* Hover State */
                                                        &:hover {
                                                            color: $color-green;
                                                            }
                                                    }
                                                }

                                                span {
                                                    padding: 8px 15px;
                                                    display: block;

                                                    &.pager_container {
                                                        position: absolute;
                                                        top: 8px;
                                                        right: 15px;
                                                        z-index: 2;
                                                        margin: 0;
                                                        padding: 0 !important;
                                                        display: inline-block;
                                                        width: auto;

                                                        ul {
                                                            margin: 0;
                                                            padding: 0;
                                                            list-style-type: none;
                                                            text-align: right;

                                                            li {
                                                                margin: 0;
                                                                padding: 0 3px;
                                                                display: inline-block;
                                                                font-size: 0.95rem;

                                                                a, span {
                                                                    &.page_link {
                                                                        padding: 3px !important;
                                                                        display: block;
                                                                        }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .dashboard-widget-content {
                                                position: relative;
                                                padding: 15px;
                                                }

                                            /* Radio Toggles */
                                            .widget-table-radio-toggles {
                                                padding: 10px 0;
                                                border-bottom: 1px solid #ddd;
                                                }

                                            /* Scroll List */
                                            .widget-scroll-list {
                                                .widget-scroll-list-inner {
                                                    padding: 0 15px 15px;
                                                    height: 230px;
                                                    overflow: auto;
                                                    overflow-x: hidden;
                                                    overflow-y: auto;
                                                    }
                                            }

                                            /* Start Activity */
                                            &.start_activity {
                                                .dashboard-widget-content {
                                                    padding: 0;

                                                    /* Header */
                                                    .page-header {
                                                        margin-bottom: 0;

                                                        .page-header-inner {
                                                            padding: 15px;
                                                            }
                                                    }

                                                    .grid-container {
                                                        .profile-topics-list {
                                                            .profile-topics-list-inner {}
                                                        }
                                                    }

                                                    /* Activities */
                                                    .profile-topics-list {
                                                        .topic-item-content {
                                                            li.activity-item {
                                                                .activity-title {
                                                                    padding-bottom: 10px;
                                                                    float: none;
                                                                    }
                                                                .activity-controls {
                                                                    float: none;

                                                                    .activity-select {
                                                                        float: none;

                                                                        select {
                                                                            width: 100%;
                                                                            max-width: 100%;
                                                                            }
                                                                    }

                                                                    .activity-button-container {
                                                                        &.activity_button_container {
                                                                            padding-top: 10px;
                                                                            padding-left: 0;
                                                                            float: none;

                                                                            button {
                                                                                &.btn {
                                                                                    margin: 0 4.9995% 0 0;
                                                                                    display: inline-block;
                                                                                    width: 29.9995%;

                                                                                    /*
                                                                                    &.start-activity-dropdown {
                                                                                        float: left;
                                                                                        }

                                                                                    &.copy-url-btn-dropdown {
                                                                                        float: right;
                                                                                        }
                                                                                    */

                                                                                    &:last-child {
                                                                                        margin-right: 0;
                                                                                        }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }

                                            /* Review My Activities */
                                            &.review_my_activities {
                                                margin-bottom: 0;

                                                .dashboard-widget-content {
                                                    padding: 0;

                                                    .myactivities_table_wrapper {
                                                        padding: 0 15px 15px;
                                                        height: 320px;
                                                        overflow: auto;
                                                        overflow-x: hidden;
                                                        overflow-y: auto;
                                                        }
                                                }
                                            }

                                        }

                                        /* Metric Item */
                                        &.metric_item {
                                            .dashboard-widget-inner {
                                                text-align: center;

                                                h5 {
                                                    padding-top: 8px;
                                                    padding-bottom: 8px;
                                                    font-size: 1.85rem;
                                                    font-weight: bold;
                                                    }
                                            }

                                            /* Colors */
                                            /* Color: Blue */
                                            &.metric-color-blue {
                                                .dashboard-widget-inner {
                                                    background-color: #dbe3ed;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }

                                            /* Color: Gray */
                                            &.metric-color-gray {
                                                .dashboard-widget-inner {
                                                    background-color: #efefef;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }

                                            /* Color: Green */
                                            &.metric-color-green {
                                                .dashboard-widget-inner {
                                                    background-color: #e7f2e1;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }

                                            /* Color: Orange */
                                            &.metric-color-orange {
                                                .dashboard-widget-inner {
                                                    background-color: #f5e3d6;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }

                                            /* Color: Red */
                                            &.metric-color-red {
                                                .dashboard-widget-inner {
                                                    background-color: #fcd6dc;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }

                                            /* Color: Yellow */
                                            &.metric-color-yellow {
                                                .dashboard-widget-inner {
                                                    background-color: #f7efd7;

                                                    h4 {
                                                        background-color: $color-white;

                                                        @include rounded(4px 4px 0 0);
                                                        }
                                                }
                                            }
                                        }
                                    }

                                    /* Summary Dashboard */
                                    .summary-dashboard {
                                        padding: 0;

                                        .tabbable-custom {
                                            &.dashboard-summaries {
                                                margin-bottom: 0;
                                                }

                                            ul {
                                                &.nav {
                                                    &.nav-tabs {
                                                        margin-bottom: 5px;
                                                        border-bottom-color: #666;

                                                        > li {
                                                            &.nav-item {
                                                                > a {
                                                                    color: #666;
                                                                    background-color: $color-white;

                                                                    @include transition(all 0.20s ease-in-out);

                                                                    &:hover {
                                                                        background-color: transparent;
                                                                        }
                                                                    &.active {
                                                                        color: $color-white;
                                                                        background-color: $color-green;
                                                                        }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            > .tab-content {
                                                padding: 0;
                                                border-width: 0;
                                                background-color: transparent;
                                                }
                                        }

                                        .dashboard-header {}

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* 1100px */
@media screen and (max-width: 1100px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {

                        /* Tab: My Summary */
                        #tab_my_summary {
                            .dashboard-widget {

                                /* col-6 */
                                &.my-summary-start-an-activity {
                                    width: 100%;
                                    }

                                /* col-6 */
                                &.my-summary-track-my-journey {
                                    width: 100%;
                                    }

                                /* col-12 */
                                &.my-summary-review-my-activities {}
                            }
                        }

                        /* Tab: User Summary */
                        #tab_user_summary {

                            .user-summary-metrics {}

                            .dashboard-widget {

                                /* col-6 */
                                &.user-summary-review-user-activities {
                                    width: 100%;
                                    }

                                /* col-6 */
                                &.user-summary-most-popular-topics {
                                    width: 100%;
                                    }

                                /* col-6 */
                                &.user-summary-logins-over-time {
                                    width: 100%;
                                    }

                                /* col-6 */
                                &.user-summary-most-popular-activities {
                                    width: 100%;
                                    }
                            }
                        }

                        /* Summary Dashboard */
                        > .container {
                            > .row {
                                > .col-12 {
                                    > .main-dashboard-form {

                                        /* Dashboard Widgets */
                                        .dashboard-widget {
                                            .dashboard-widget-inner {

                                                /* Start Activity */
                                                &.start_activity {
                                                    .dashboard-widget-content {}
                                                }
                                            }

                                            &.metric_item {}

                                            &.my-summary-start-an-activity {
                                                width: 100%;
                                            }

                                            &.my-summary-track-my-journey {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

/* 900px */
@media screen and (max-width: 900px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {

                        /* Tab: My Summary */
                        #tab_my_summary {
                            .dashboard-widget {

                                /* col-4 */
                                &.metric_item {
                                    width: 100%;
                                    }

                                /* col-6 */
                                &.my-summary-start-an-activity {
                                    .dashboard-widget-inner {

                                        /* Scroll List */
                                        .widget-scroll-list {
                                            .widget-scroll-list-inner {
                                                height: 355px;
                                                }
                                        }
                                    }
                                }

                                /* col-6 */
                                &.my-summary-track-my-journey {

                                    }

                                /* col-12 */
                                &.my-summary-review-my-activities {

                                    }
                            }
                        }

                        /* Tab: User Summary */
                        #tab_user_summary {

                            .user-summary-metrics {
                                .metrics_container {
                                    > .row {
                                        &.widgets-row {

                                            /* col-4 */
                                            .dashboard-widget {
                                                width: 50%;
                                                }
                                        }
                                    }
                                }
                            }

                            .dashboard-widget {

                                /* col-6 */
                                &.user-summary-review-user-activities {

                                    }

                                /* col-6 */
                                &.user-summary-most-popular-topics {

                                    }

                                /* col-6 */
                                &.user-summary-logins-over-time {

                                    }

                                /* col-6 */
                                &.user-summary-most-popular-activities {

                                    }
                            }
                        }

                    }
                }
            }
        }
    }
}

/* 800px */
@media screen and (max-width: 800px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {

                        .dashboard-header {
                            > .row {
                                > .col-4,
                                > .col-8 {
                                    width: 100%;
                                    }

                                > .col-4 {
                                    padding-bottom: 10px;
                                    }
                                > .col-8 {
                                    text-align: left !important;
                                    }
                            }
                        }

                        &.company_dashboard {
                            .main-dashboard-form {
                                .widgets-row {
                                    .col-6 {
                                        width: 100%;
                                        }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 700px */
@media screen and (max-width: 700px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {
                        &.company_dashboard {
                            .main-dashboard-form {
                                .dashboard-widget {
                                    &.metric_item {
                                        width: 49.9999998%;
                                        }
                                }
                            }
                        }

                        /* Track My Journey */
                        .dashboard-widget {
                            &.my-summary-track-my-journey {

                                table {
                                    &.dashboard-widget-table {
                                        width: 100%;
                                        }
                                }

                                /* col-4 */
                                .sysactivity_item {
                                    margin-bottom: 15px;
                                    width: 100%;

                                    &:last-child { margin-bottom: 0; }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 600px */
@media screen and (max-width: 600px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {

                        /* Summary Dashboard */
                        > .container {
                            > .row {
                                > .col-12 {
                                    > .main-dashboard-form {

                                        /* Dashboard Widgets */
                                        .dashboard-widget {
                                            .dashboard-widget-inner {

                                                /* Start Activity */
                                                &.start_activity {
                                                    .dashboard-widget-content {}
                                                }
                                            }

                                            &.metric_item {
                                                width: 100%;
                                                }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 550px */
@media screen and (max-width: 550px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {
                        &.company_dashboard {
                            .main-dashboard-form {
                                .dashboard-widget {
                                    &.metric_item {
                                        width: 100%;
                                        }
                                }
                            }
                        }

                        /* Tab: User Summary */
                        #tab_user_summary {

                            .user-summary-metrics {
                                .metrics_container {
                                    > .row {
                                        &.widgets-row {

                                            /* col-4 */
                                            .dashboard-widget {
                                                width: 100%;
                                                }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

/* 500px */
@media screen and (max-width: 500px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {

                        .dashboard-header {
                            .col-6 {
                                width: 100%;
                                }
                        }
                    }
                }
            }
        }
    }
}

/* 450px */
@media screen and (max-width: 450px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .dashboard {
                        > .container {
                            > .row {
                                > .col-12 {
                                    > .main-dashboard-form {
                                        .dashboard-widget {
                                            .dashboard-widget-inner {
                                                &.start_activity {
                                                    .dashboard-widget-content {
                                                        .profile-topics-list {
                                                            .topic-item-content {
                                                                li {
                                                                    &.activity-item {
                                                                        .activity-controls {
                                                                            .activity-button-container {
                                                                                &.activity_button_container {
                                                                                    button {
                                                                                        &.btn {
                                                                                            margin: 10px 1.5% 0;
                                                                                            display: block;
                                                                                            float: left;
                                                                                            width: 29.999999%;
                                                                                            max-width: 29.999999%;

                                                                                            &.dn-ns {
                                                                                                position: absolute;
                                                                                                top: -1px;
                                                                                                left: -1px;
                                                                                                margin: -1px 0 0 -1px;
                                                                                                width: 1px;
                                                                                                height: 1px;
                                                                                                overflow: hidden;

                                                                                                @include opacity(0.0000001);
                                                                                                }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
