.global-search-container {
    padding-bottom: 15px;

    input[type="text"] {
        margin: 0;
        padding: 0 10px;
        display: block;
        width: 100%;
        height: 36px;
        border: 2px solid #ebedf2;

        @include rounded(4px);
    }
}

.filter-container {
    padding-top: 15px;
    padding-bottom: 15px;

    /* Clear Floats */
    &::after {
        clear: both;
        margin: 0;
        padding: 0;
        display: table;
        width: 0;
        height: 0;
        font-size: 0;
        line-height: 0;
        content: ' ';
        visibility: hidden;
        overflow: hidden;
        *zoom: 1;
        }

    ul {
        margin: 0 -15px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        > li {
            padding: 0 15px 15px;
            flex: 0 0 auto;
            width: 25%;
            max-width: 25%;

            label {
                display: block;
                }

            input[type="text"],
            select {
                margin: 0;
                padding: 0 10px;
                display: block;
                width: 100%;
                height: 36px;
                border: 2px solid #ebedf2;

                @include rounded(4px);
                }
        }
    }
}

/* Select2 */
body {
    .select2-container--default {
        .company_dashboard_dropdown, .r1l_select2_dropdown {
            border-width: 2px;

            &.select2-dropdown--below {
                margin-top: -12px;

                @include rounded(0 0 4px 4px);
                @include boxshadow(0 15px 20px rgba($color-black,0.05));
                }

            .select2-results {
                .apply_filters_container {
                    padding: 10px 15px 10px;
                    border-top: 1px solid #ebedf2;

                    a {
                        margin-right: 0;
                        width: 100%;
                        cursor: pointer;
                        background-color: $color-green-2;

                        /* Hover State */
                        &:hover {
                            border-color: $color-green-3;
                            background-color: $color-green-3;
                            }
                    }
                }

                /* Selected Checkmark */
                ul {
                    &.select2-results__options {
                        li {
                            &.select2-results__option {
                                position: relative;
                                padding: 5px 15px 5px 40px;
                                border-bottom: 1px dashed #ebedf2;

                                &::before {
                                    position: absolute;
                                    top: 6px;
                                    left: 10px;
                                    margin: 0;
                                    }

                                &.select2-results__option--selected {
                                    &::before {
                                        background-color: $color-green-3;
                                        }
                                }
                            }
                        }
                    }
                }
            }
        }

        .r1l_select2_dropdown {
            &.select2-dropdown--below {
                border-radius: 6px;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
            }

            .select2-results {
                .select2-results__options {
                    .select2-results__option {
                        padding-right: 20px;
                        vertical-align: middle;

                        &:before {
                            content: "";
                            display: inline-block;
                            position: relative;
                            height: 20px;
                            width: 20px;
                            border: 2px solid #e9e9e9;
                            border-radius: 4px;
                            background-color: #fff;
                            margin-right: 20px;
                            vertical-align: middle;
                        }


                        &.select2-results__option--selected {
                            background-color: #fff;

                            &:before {
                                font-family: "Font Awesome 6 Regular", Arial, Helvetica, Sans-serif;
                                content: "\f00c";
                                color: #fff;
                                background-color: #f77750;
                                border: 0;
                                display: inline-block;
                                padding-left: 4px;
                            }
                        }

                        &.select2-results__option--highlighted {
                            background-color: #eaeaeb;
                            color: #272727;
                        }
                    }
                }

                .apply_filters_container {
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }

        .r1l_select2_selection {
            .select2-search--inline {
                .select2-search__field {
                    width: 90% !important;
                    margin: 8px 15px 0;
                }
            }

            &.select2-selection--multiple {
                border-radius: 4px;
                margin-bottom: 10px;
                border-width: 2px;
            }
        }

        .select2-selection--single,
        .select2-selection--multiple {
            ul {
                &.select2-selection__rendered {
                    margin: 0 !important;
                    padding: 0 !important;
                    list-style-type: none !important;

                    > li {
                        &.select2-selection__choice {
                            margin: 3px 0 0 3px !important;
                            width: auto !important;
                            }
                    }
                }
            }
        }

        .select2-container--focus {
            .r1l_select2_selection {
                &.select2-selection--multiple {
                    border-color: #f77750;
                    border-width: 2px;
                }
            }
        }

    }
}


/* Media Queries */
/* 1000px */
@media screen and (max-width: 1000px){
    .filter-container {

        ul {
            > li {
                width: 33.33332%;
                width: 33.33332%;
                max-width: 33.33332%;
                }
        }
    }
}

/* 700px */
@media screen and (max-width: 700px){
    .filter-container {

        ul {
            > li {
                width: 49.99998%;
                width: 49.99998%;
                max-width: 49.99998%;
                }
        }
    }
}

/* 550px */
@media screen and (max-width: 550px){
    .filter-container {

        ul {
            > li {
                width: 100%;
                max-width: 100%;
                }
        }
    }
}
