#app {
    .profile-main {
        div {
            &.dataTables_wrapper {
                &.form-inline {
                    display: block;
                    }

                /* Processing Notice */
                div {
                    &.dataTables_processing {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 99999;
                        width: 200px;
                        margin-left: -100px;
                        margin-top: -26px;
                        padding: 1em 0;
                        color: $color-white;
                        text-align: center;
                        background-color: #1e1e2d;
                        background-color: rgba(30,30,45,0.90);

                        @include boxshadow(0 1px 4px rgba($color-black,0.3));

                        &::after {
                            content: 'Processing...';
                            }

                        div { display: none; }
                    }
                }

                /* Table */
                table {
                    &.dataTable {
                        margin-top: 0 !important;
                        margin-bottom: 15px !important;
                        padding-top: 30px;
                        width: 100% !important;
                        color: #666;

                        &::after {
                            clear: both;
                            margin: 0;
                            padding: 0;
                            display: table;
                            width: 0;
                            height: 0;
                            font-size: 0;
                            line-height: 0;
                            content: ' ';
                            overflow: hidden;
                            visibility: hidden;
                            *zoom: 1;
                            }

                        a {
                            text-decoration: none;

                            /* Hover State */
                            &:hover {
                                text-decoration: none;
                                }
                        }

                        thead {
                            tr {}

                            th {
                                padding: 10px 15px;
                                font-size: 0.80rem;
                                font-weight: bold;
                                }
                        }

                        tbody {
                            td {
                                padding: 15px !important;
                                color: #666;
                                font-size: 0.90rem;
                                }

                            tr {
                                @include transition(
                                    color 0.10s ease-in-out,
                                    background 0.10s ease-in-out
                                );

                                /* Hover State */
                                &:hover {
                                    /* background: rgba($color-green,0.15) !important; */
                                    background: #f5f8fa !important;

                                    > td,
                                    > th {
                                        --bs-table-accent-bg: transparent !important;
                                        background: transparent !important;
                                        }
                                }

                                /* Child Information */
                                &.child {
                                    td {
                                        &.child {
                                            padding: 0 !important;

                                            ul {
                                                &.dtr-details {
                                                    margin: 0;
                                                    padding: 15px 5px;
                                                    display: block;
                                                    list-style-type: none;
                                                    background-color: $color-white;

                                                    > li {
                                                        padding: 15px 0;
                                                        display: block;

                                                        &:first-child { padding-top: 0; }
                                                        &:last-child { padding-bottom: 0; }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        /* Expand/Collapse Toggle */
                        &.dtr-inline {
                            &.collapsed {
                                > tbody {
                                    > tr {

                                        /* Left Padding when Accordion Active/Visible */
                                        > td {
                                            &.dtr-control {
                                                padding-left: 40px !important;
                                                }
                                        }

                                        /* Collapsed */
                                        //&[role="row"] {
                                            > td,
                                            > th {
                                                &.dtr-control {
                                                    position: relative;

                                                    /* Icon */
                                                    &::before {
                                                        position: absolute;
                                                        top: 16px;
                                                        left: 7px;
                                                        margin: 0;
                                                        padding: 0;
                                                        display: block;
                                                        width: 18px;
                                                        height: 18px;
                                                        color: $color-black;
                                                        font-family: $faregular;
                                                        font-size: 0.80rem;
                                                        line-height: 18px;
                                                        text-align: center;
                                                        text-indent: 0 !important;

                                                        /* https://fontawesome.com/v5.15/icons/plus?style=regular */
                                                        content: '\f067';
                                                        border: 1px solid rgba($color-black,0.10);
                                                        background-color: $color-white;

                                                        @include rounded(3px);
                                                        @include boxshadow(none);
                                                        @include boxsizing(content-box);
                                                        @include transition(
                                                            color 0.10s ease-in-out,
                                                            border-color 0.10s ease-in-out,
                                                            background-color 0.10s ease-in-out
                                                        );
                                                        }
                                                }
                                            }
                                        //}

                                        /* Expanded */
                                        &.parent,
                                        &.dt-hasChild {
                                            /* background: rgba($color-green,0.15) !important; */
                                            background: #f5f8fa !important;

                                            &:hover {
                                                > th,
                                                > td {
                                                    background: transparent !important;
                                                    }
                                            }

                                            > td,
                                            > th {
                                                &.dtr-control {
                                                    &::before {
                                                        color: $color-white;

                                                        /* https://fontawesome.com/v5.15/icons/minus?style=regular */
                                                        content: '\f068';
                                                        background-color: $color-charcoal;
                                                        }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                div {

                    /* Data Tables Info */
                    &.dataTables_info {
                        margin: 0;
                        padding-top: 10px;
                        display: block;
                        float: left;
                        }

                    /* Data Tables Pagination */
                    &.dataTables_paginate {
                        margin: 0;
                        padding-top: 10px;
                        display: block;
                        float: right;

                        ul {
                            &.pagination {
                                margin: 0;
                                padding: 0;
                                display: block;
                                float: right;
                                list-style-type: none;

                                > li {
                                    margin: 0 0 0 8px;
                                    display: block;
                                    float: left;

                                    &:first-child {
                                        margin-left: 0;
                                        }

                                    > a {
                                        padding: 8px 10px;
                                        display: block;
                                        color: #4d97c1;
                                        text-decoration: none;
                                        background-color: rgba(77, 151, 193, 0.20);

                                        @include rounded(3px);
                                        @include transition(
                                            color 0.10s ease-in-out,
                                            border-color 0.10s ease-in-out,
                                            background-color 0.10s ease-in-out
                                        );
                                        }

                                    /* Hover/Active States */
                                    &:hover,
                                    &.active {
                                        > a {
                                            color: #fff;
                                            text-decoration: none;
                                            background-color: #4d97c1;
                                            }
                                    }

                                    /* Disabled */
                                    &.disabled {
                                        &:hover {
                                            > a {
                                                color: #4d97c1;
                                                text-decoration: none;
                                                cursor: default;
                                                background-color: rgba(77, 151, 193, 0.20);
                                                }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* Table Actions */
                .actions {
                    text-align: right;
                    }

                a {
                    &.action-item {

                        /*
                        margin: 0 0 0 10px;
                        padding: 9px 15px;
                        display: inline-block;
                        color: #4d97c1;
                        text-decoration: none;
                        background-color: rgba(77, 151, 193, 0.20);

                        @include rounded(3px);
                        */

                        padding-right: 3px;
                        padding-left: 3px;
                        display: inline-block;
                        color: #666;
                        text-decoration: none;

                        @include transition(
                            color 0.10s ease-in-out,
                            border-color 0.10s ease-in-out,
                            background-color 0.10s ease-in-out
                        );

                        i {
                            padding-right: 8px;
                            }

                        &:first-child {
                            margin-left: 0;
                            }

                        /* Hover State */
                        &:hover {
                            /*
                            color: #fff;
                            text-decoration: none;
                            background-color: #4d97c1;
                            */

                            color: #4d97c1;
                            text-decoration: none;
                            }
                    }

                    &.view_user_dashboard_trigger,
                    &.btn-view-report {
                        display: inline-block;
                        /*float: right;*/
                        line-height: 19px;
                        text-align: center;

                        &::before {
                            font-family: $falight;
                        }

                        /* Hover State */
                        &:hover {
                            color: $color-green;
                        }
                    }
                }

                /* Length */
                .dataTables_length {
                    float: left;

                    label {
                        select {
                            &.form-control {
                                margin-right: 8px;
                                margin-left: 8px;
                                }
                        }
                    }
                }

                /* Table Top Buttons */
                .dt-buttons {
                    &.btn-group {
                        float: right;
                        }
                }

                /* Topic Info Block */
                .topic_activity_info {
                    padding: 15px 0 0;

                    &:first-child { padding-top: 0; }
                }

                /* Program Select */
                .activity_program_dropdown_container {
                    margin: 0;
                    padding: 5px 0;

                    select {
                        border: 1px solid $color-darkgray;
                        background-color: $color-white;

                        @include rounded(3px);
                    }
                }

                /* Topic Activity Buttons */
                .activity_button_container {
                    button {
                        font-weight: 400;
                        font-weight: normal;

                        &[disabled] {
                            color: $color-black;
                            cursor: no-drop;
                            border-color: $color-lightgray-5;
                            background-color: $color-lightgray-5;
                            }
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* 800px */
@media screen and (max-width: 800px){
    #app {
        .profile-main {
            div {
                &.dataTables_wrapper {
                    .actions {
                        text-align: left;
                        }
                }
            }
        }
    }
}

/* 767px */
@media screen and (max-width: 767px){
    #app {
        .profile-main {
            div {
                &.dataTables_wrapper {
                    .dt-buttons {
                        &.btn-group {
                            width: auto;
                            }
                    }
                }
            }
        }
    }
}

/* 400px */
@media screen and (max-width: 400px){
    #app {
        .profile-main {
            div {
                &.dataTables_wrapper {
                    .dt-buttons {
                        &.btn-group {
                            padding-top: 15px;
                            width: 100%;
                            }
                    }
                }
            }
        }
    }
}

.user-info-cell {
    position: relative;
    padding-left: 40px;
    white-space: nowrap;

    img {
        position: absolute;
        top: 50%;
        left: 0;

        @include transform(translateY(-50%));
        }

    a {
        &.view_user_dashboard_trigger,
        &.btn-view-report {
            display: inline-block;
            /*float: right;*/
            width: 24px;
            height: 19px;
            color: #666;
            line-height: 19px;
            text-align: center;

            &::before {
                font-family: $falight;
                }

            /* Hover State */
            &:hover {
                color: $color-green;
                }
        }

        &.view_user_dashboard_trigger {

            /* https://fontawesome.com/icons/file-chart-pie?f=classic&s=light */
            /* &::before { content: '\f65a'; } */
        }
        &.btn-view-report {

            /* https://fontawesome.com/icons/file-chart-column?f=classic&s=light */
            /* &::before { content: '\f659'; } */
        }
    }

    a,
    span {
        &.username {
            margin-right: 10px;

            &.username-link {

            }
        }
    }
}
