form {
  .form-group {
    .label_container {
      margin-bottom: 2px;

      .hint_icon {
        display: inline-block;
        color: #4f4f51;
        margin-left: 5px;
        cursor: pointer;
        background: #efefef;
        border-radius: 10px;

        &:before {
          content: "\003F";
          padding: 5px 8px;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .hint_html {
        position: absolute;
        z-index: 5;
        border: 1px solid #4f4f51;
        margin-top: -150px;
      }
    }
  }
}