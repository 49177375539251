.topic-view  {
    footer {
        line-height: unset;
        text-align: unset;
    }
}
.activity-view  {
    footer {
        line-height: unset;
        text-align: unset;
    }
}

footer {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #f1f1f1;
    p {
        margin-bottom: 0;
    }
}

.profile-footer {
    padding-top: 15px;
    padding-bottom: 15px;

    ul {
        &.footer-menu {
            margin: 0;
            padding: 0 15px;
            list-style-type: none;

            > li {
                position: relative;
                margin: 0;
                padding: 0 14px 0 0;
                display: block;
                float: left;

                &::before {
                    position: absolute;
                    top: 50%;
                    right: 7px;
                    margin: -6px 0 0;
                    display: block;
                    width: 1px;
                    height: 12px;
                    content: ' ';
                    background-color: $color-charcoal;
                    }

                &:last-child {
                    padding-right: 0;

                    &:before { display: none; }
                }

                > a {
                    display: block;
                    color: $color-charcoal;

                    @include transition(color 0.10s ease-in-out);

                    /* Hover State */
                    &:hover {
                        color: $color-green;
                        }
                }
            }
        }
    }
}
