.privacy-container {

    .privacy-agreement {
        padding-bottom: 15px;

        label {
            &[for="password"] {
                margin-bottom: 10px;
                font-weight: normal;
                font-weight: 400;
                }
        }
        .r1-profile-t-and-c-wrapper {}
    }

    .privacy-delete-account {
        label {
            &[for="password"] {
                margin-bottom: 10px;
                font-weight: normal;
                font-weight: 400;
                }
            &[for="delete-instructions"] {
                margin-bottom: 5px;
                }
        }

        .r1-profile-delete-account-data-wrapper {
            #form-delete-account-data {
                padding-top: 10px;
                }
        }
    }
}
