.creds_container {
  background: #76bc43;
  color: #fff;
  padding: 10px;

  .creds_heading {
    font-weight: bold;
  }

  .creds_info {
    font-size: 12px;
    font-style: italic;
  }

  table.tenant_info {
    font-size: 13px;
    margin-top: 5px;

    tr {
      td {
        vertical-align: top;
      }
    }
  }
}

#modal-azure-saml-configuration, .modal-full-width {
  .modal-dialog {
    width: auto;
    max-width: 90%;
  }
}