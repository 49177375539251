.alert {
    position: relative;

    &.alert-dismissible {
        button {
            &.close {
                position: absolute;
                top: 17px;
                right: 15px;
                z-index: 1;
                margin: 0;
                padding: 0;
                display: block;
                width: 20px;
                height: 20px;
                font-size: 1.20rem;
                line-height: 18px;
                text-align: center;
                cursor: pointer;
                border: 1px solid $color-lightgray-6;
                background-color: rgba($color-white,0.75);

                @include rounded(3px);

                /* Hover State */
                &:hover {
                    background-color: rgba($color-white,1.00);
                    }
            }
        }
    }

    &.alert-danger {}
}
