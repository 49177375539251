a {
    &.modal-feedback-popup-launch {
        position: fixed;
        top: 50%;
        right: -45px;
        z-index: 999995;
        margin: -60px 0 0;
        padding: 0 0 0 38px;
        display: block;
        width: 120px;
        height: 34px;
        color: $color-white;
        font-size: 1.00rem;
        line-height: 34px;
        background-color: $color-green;

        @include rounded(5px 5px 0 0);
        @include transform(rotate(-90deg));
        @include transition(opacity 0.10s ease-in-out);

        &::before {
            position: absolute;
            top: 0;
            left: 10px;
            font-family: $faregular;
            content: '\f4a6';

            @include transform(rotate(90deg));
            }

        /* Hover State */
        &:hover {
            @include opacity(0.80);
            }
    }
}

.modal-feedback-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999997;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($color-white,0.80);

    backdrop-filter: blur(3px);
    }

#modal-feedback-popup {
    position: fixed;
    top: 150px;
    left: 50%;
    z-index: 999999;
    width: 100%;
    max-width: 350px;
    border: 1px solid #ddd;
    background-color: $color-white;

    @include rounded(5px);
    @include transform(translateX(-50%) !important);
    @include boxshadow(0 0 15px rgba($color-black, 0.20));

    .modal-header {
        position: relative;
        padding: 11px 40px 11px 10px;
        color: #222;
        border-bottom: 1px solid #ddd;
        background-color: #f2f2f2 !important;
        background-color: rgba(#f2f2f2,0.50) !important;

        @include rounded(5px 5px 0 0);

        .header-title {
            padding: 3px 0;
            font-size: 1.05rem;
            font-weight: bold;
            font-weight: 500;
            }

        button {
            &.close-modal-feedback-popup {
                position: absolute;
                top: 12px;
                right: 10px;
                margin: 0;
                padding: 0;
                display: block;
                width: 20px;
                height: 20px;
                color: #222;
                font-size: 1.10rem;
                line-height: 20px;
                text-align: center;
                cursor: pointer;
                border: 0 none;
                background-color: transparent;

                @include transition(color 0.10s ease-in-out);

                &::-moz-focus-inner {
                    margin: 0;
                    padding: 0;
                    border: 0 none;
                    }

                &::before {
                    font-family: $faregular;
                    content: '\f00d';
                    }

                /* Hover State */
                &:hover {
                    color: #b10000;
                    }
            }
        }
    }

    .modal-content {
        padding: 10px;

        .form-field {
            label {
                margin: 0;
                padding: 0 0 5px;
                display: block;
                }

            textarea {
                margin: 0;
                padding: 5px;
                display: block;
                width: 100%;
                height: 70px;
                font-family: Arial, Helvetica, Sans-serif;
                font-size: 0.90rem;
                border: 1px solid #555;

                resize: none;

                @include rounded(3px);
                @include transition(border-color 0.10s ease-in-out);

                &:hover,
                &:focus {
                    border-color: $color-green;
                    }
            }
        }

        .button-container {
            padding: 10px 0 0;

            button {
                margin: 0;
                padding: 8px 10px;
                display: block;
                width: 100%;
                color: $color-white;
                font-size: 1.00rem;
                text-align: center;
                cursor: pointer;
                border: 0 none;
                background-color: $color-green;

                @include rounded(3px);
                @include transition(
                    color 0.10s ease-in-out,
                    border-color 0.10s ease-in-out,
                    background-color 0.10s ease-in-out
                );

                /* Hover State */
                &:hover {
                    @include opacity(0.80);
                    }

                &::-moz-focus-inner {
                    margin: 0;
                    padding: 0;
                    border: 0 none;
                    }
            }
        }
    }
}

