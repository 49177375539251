/* User Results Dashboard */
body {
    &.profile-content-body {
        #app {
            .profile-main {
                .user-results-dashboard {

                    .tab-content {
                        padding: 15px 0 0;
                        border: 0 none;
                        background-color: transparent;

                        #tab-user-activities-content {
                            padding: 10px;
                            border: 1px solid #ddd;
                            background-color: $color-white;

                            @include rounded(4px);
                            }
                    }

                    .user-dashboard-selection {}

                    .user_dashboard_content, .user_progress_content {
                        padding-top: 15px;
                        }

                    .activities-actions {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 1;
                        padding: 0 0 15px;
                        width: auto;

                        ul {
                            &.activities-actions-list {
                                margin: 0;
                                padding: 0;
                                list-style-type: none;

                                &::after {
                                    clear: both;
                                    margin: 0;
                                    padding: 0;
                                    display: table;
                                    width: 0;
                                    height: 0;
                                    font-size: 0;
                                    line-height: 0;
                                    content: ' ';
                                    overflow: hidden;
                                    visibility: hidden;
                                    }

                                li {
                                    margin: 0;
                                    padding: 0 5px 0 0;
                                    display: block;
                                    float: left;

                                    &:last-child { padding-right: 0; }

                                    &.activities-action-label {
                                        padding-right: 10px;
                                        font-weight: bold;
                                        font-weight: 600;
                                        line-height: 2.25rem;
                                        }

                                    &.activities-action-item {
                                        a {
                                            &.btn {
                                                position: relative;
                                                padding: 7px 15px 7px 35px;
                                                display: block;
                                                color: #333;
                                                border: 1px solid #aaa;
                                                background-color: $color-white;

                                                @include rounded(3px);

                                                &::before {
                                                    position: absolute;
                                                    top: 8px;
                                                    left: 10px;
                                                    display: block;
                                                    width: 20px;
                                                    height: 20px;
                                                    font-family: $faregular;
                                                    font-size: 1.15rem;
                                                    line-height: 20px;
                                                    text-align: center;
                                                    }

                                                &.btn-reset-order {
                                                    color: #333;
                                                    border-color: #ddd;
                                                    background-color: $color-white;

                                                    &::before { content: '\f2ea'; }
                                                }

                                                &.btn-recent-activity {
                                                    color: #333;
                                                    border-color: #ddd;
                                                    background-color: $color-white;

                                                    &::before { content: '\f1da'; }
                                                }

                                                &.btn-my-recent-activity {
                                                    color: #333;
                                                    border-color: #ddd;
                                                    background-color: $color-white;

                                                    &::before { content: '\f1da'; }
                                                }

                                                &.btn-save-order {
                                                    color: #333;
                                                    border-color: #ddd;
                                                    background-color: $color-white;

                                                    &::before { content: '\f0c7'; }
                                                }

                                                /* Hover State */
                                                &:hover {
                                                    color: $color-white;
                                                    border-color: #73b02a;
                                                    background-color: #73b02a;
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    /* Tabs Row */
                    .user-results-tabs-row {
                        .user-results-tabs-nav {
                            position: relative;
                            margin-bottom: 0;
                            padding: 0;
                            }

                        ul {
                            &.user-results-tabs {
                                margin: 0 !important;
                                padding: 0;
                                display: block;
                                list-style-type: none;
                                border-bottom: 1px solid #666 !important;

                                &::after {
                                    clear: both;
                                    margin: 0;
                                    padding: 0;
                                    display: table;
                                    width: 0;
                                    height: 0;
                                    font-size: 0;
                                    line-height: 0;
                                    content: " ";
                                    visibility: hidden;
                                    overflow: hidden;
                                    }

                                li {
                                    &.user-results-tab {
                                        margin: 0 5px 0 0;
                                        padding: 0;
                                        display: block;
                                        float: left;
                                        border: 0 none;
                                        background-color: transparent;

                                        a {
                                            &.nav-link {
                                                position: relative;
                                                margin: 0;
                                                padding: 8px !important;
                                                display: block;
                                                // color: $color-darkgray;
                                                text-decoration: none;
                                                border: 1px solid $color-lightgray-3;
                                                background-color: $color-white;

                                                @include rounded(3px 3px 0 0);

                                                /* Active State */
                                                &.active {
                                                    color: $color-white;
                                                    //border-color: #4d97c1;
                                                    //background-color: #4d97c1;
                                                    }
                                            }
                                        }

                                        &.my-results {}
                                        &.my-progress {}

                                        /* Active/Hover States */
                                        &:hover,
                                        &.active {}
                                    }
                                }
                            }
                        }
                    }

                    /* Blocks Row */
                    .user-widget-blocks-row {
                        .col-6 {}

                        /* Individual Blocks */
                        .user-widget-block {
                            margin-bottom: 30px;

                            /* Inner Block Wrapper */
                            .user-widget-block-inner {
                                @include boxshadow(0 0 20px rgba($color-black, 0.05));
                                @include rounded(5px);
                                }

                            /* Header */
                            .user-widget-block-header {
                                position: relative;
                                padding: 10px 15px 10px 30px;
                                color: #333;
                                text-align: left;
                                border-bottom: 1px solid #aaa;
                                background-color: $color-white;
                                cursor: pointer;

                                @include rounded(5px 5px 0 0);
                                @include userselect(none);

                                &::before {
                                    position: absolute;
                                    top: 10px;
                                    right: 15px;
                                    display: block;
                                    width: 24px;
                                    height: 24px;
                                    color: #666;
                                    font-family: $faregular;
                                    font-size: 1.15rem;
                                    content: '\f044';
                                    line-height: 24px;
                                    text-align: center;
                                    }

                                .user-widget-block-header-color {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: block;
                                    width: 20px;
                                    height: 100%;
                                    background-color: $color-green-2;

                                    @include rounded(5px 0 0);
                                    }

                                h2 {
                                    margin: 0;
                                    padding: 0;
                                    color: #333;
                                    font-size: 1.25rem;
                                    font-weight: bold;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    }

                                h3 {
                                    margin: 0;
                                    padding: 0 0 3px;
                                    color: #333;
                                    font-size: 1.15rem;
                                    font-weight: normal;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    }
                            }

                            /* Content */
                            .user-widget-block-content {
                                position: relative;
                                background-color: $color-white;

                                .user-widget-pagination-nav {
                                    &.count-1 {
                                        display: none;
                                        }
                                }

                                /* Pagination */
                                ul {
                                    &.user-widget-pagination {
                                        position: absolute;
                                        top: 15px;
                                        left: 15px;
                                        z-index: 1;
                                        margin: 0;
                                        padding: 0;
                                        list-style-type: none;

                                        li {
                                            &.user-widget-page {
                                                padding: 0 5px 0 0;
                                                display: block;
                                                float: left;

                                                &:last-child { padding-right: 0; }

                                                a {
                                                    padding: 5px 8px;
                                                    display: block;
                                                    color: $color-darkgray;
                                                    font-size: 0.80rem;
                                                    text-decoration: none;
                                                    background-color: $color-lightgray-2;

                                                    @include rounded(3px);

                                                    &:hover,
                                                    &.active {
                                                        background-color: #666;
                                                        color: $color-lightgray-2;
                                                        }
                                                }

                                                /* Active/Hover States */
                                                &:hover,
                                                &.active {}
                                            }
                                        }
                                    }
                                }

                                /* Chart */
                                .user-widget-chart, .user-widget-progress-chart, .user-widget-trends-chart {
                                    padding: 55px 15px 15px;
                                    min-height: 100px;
                                    }
                            }

                            /* Footer */
                            .user-widget-block-footer {
                                position: relative;
                                padding: 8px 15px;
                                border-top: 1px solid $color-lightgray-3;
                                background-color: $color-white;

                                @include rounded(0 0 5px 5px);

                                &::after {
                                    clear: both;
                                    margin: 0;
                                    padding: 0;
                                    display: table;
                                    width: 0;
                                    height: 0;
                                    font-size: 0;
                                    line-height: 0;
                                    content: " ";
                                    visibility: hidden;
                                    overflow: hidden;
                                    }

                                .user-widget-footer-filters-nav {
                                    position: relative;
                                    z-index: 2;

                                    &.row {
                                        display: block;

                                        > .col-md-4 {
                                            width: 33.322222%;

                                            &.left-col {
                                                float: left;
                                                }
                                            &.right-col {
                                                float: right;
                                                }
                                        }
                                    }
                                }

                                .useractivity_completed_date {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 1;
                                    display: block;
                                    width: 100%;
                                    font-size: 0.75rem;
                                    text-align: center;
                                    }

                                ul {
                                    &.user-widget-footer-filters {
                                        margin: 0;
                                        padding: 5px 0 0;
                                        list-style-type: none;

                                        &::after {
                                            clear: both;
                                            margin: 0;
                                            padding: 0;
                                            display: table;
                                            width: 0;
                                            height: 0;
                                            font-size: 0;
                                            line-height: 0;
                                            content: " ";
                                            visibility: hidden;
                                            overflow: hidden;
                                            }

                                        li {
                                            &.user-widget-footer-filter {
                                                padding: 0 5px 0 0;
                                                display: block;
                                                float: left;
                                                font-size: 1.15rem;

                                                &:last-child { padding-right: 0; }

                                                a {
                                                    display: block;
                                                    /* color: $color-darkgray; */
                                                    color: #666;
                                                    text-decoration: none;

                                                    &::before {
                                                        font-family: $fasolidsharp;
                                                        }
                                                }

                                                /* Icon: Bar Chart */
                                                &.bar-chart {
                                                    a {

                                                        /* https://fontawesome.com/icons/chart-simple?s=solid&f=classic*/
                                                        &::before { content: '\e473'; }
                                                    }
                                                }

                                                /* Icon: Pie Chart */
                                                &.pie-chart {
                                                    a {

                                                        /* https://fontawesome.com/icons/chart-pie?s=solid&f=classic*/
                                                        &::before { content: '\f200'; }
                                                    }
                                                }

                                                /* Icon: Tree Map */
                                                &.tree-map {
                                                    a {

                                                        /* https://fontawesome.com/icons/chart-tree-map?s=solid&f=classic */
                                                        &::before { content: '\e0ea'; }
                                                    }
                                                }

                                                /* Icon: Funnel Chart */
                                                &.funnel-chart {
                                                    a {

                                                        /* https://fontawesome.com/icons/filter?s=solid&f=classic */
                                                        &::before { content: '\f0b0'; }
                                                    }
                                                }

                                                /* Icon: Scatter Chart */
                                                &.scatter-chart {
                                                    a {

                                                        /* https://fontawesome.com/icons/filter?s=solid&f=classic */
                                                        &::before { content: '\f7ee'; }
                                                    }
                                                }

                                                /* Icon: Area Chart */
                                                &.area-chart {
                                                    a {

                                                        /* https://fontawesome.com/icons/filter?s=solid&f=classic */
                                                        &::before { content: '\f1fe'; }
                                                    }
                                                }

                                                /* Active/Hover States */
                                                &:hover,
                                                &.active {
                                                    a {
                                                        color: $color-green;
                                                        }
                                                }
                                            }
                                        }

                                        &.left-col {}
                                        &.right-col {
                                            float: right;

                                            li {
                                                float: left;
                                                }
                                        }
                                    }
                                }
                            }

                        }

                        /* My Results Blocks */
                        &.my-results {}

                        /* My Progress Blocks */
                        &.my-progress {}

                    }
                }

                #topic_items {
                    .topic {
                        border: 1px solid #ccc;
                        margin: 5px auto;
                        padding: 5px;
                        cursor: pointer;
                        }
                }
            }
        }
    }
}

/* Media Queries */
@media screen and (max-width: 1000px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .user-results-dashboard {
                        .row.user-widget-blocks-row {
                            .user-widget-block {
                                &.col-6 {
                                    width: 100%;
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 975px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {

                    .user-results-dashboard {
                        .row {
                            &.user-results-tabs-row {
                                ul {
                                    &.user-results-tabs {
                                        padding-left: 0 !important;
                                        border-bottom-width: 0 !important;

                                        li {
                                            &.user-results-tab {
                                                a {
                                                    &.nav-link {
                                                        @include rounded(0);
                                                        }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .user-results-dashboard {
                        .activities-actions {
                            position: static;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .user-results-dashboard {
                        .user-widget-blocks-row {
                            .user-widget-block {
                                &.col-1,&.col-2,&.col-3,
                                &.col-4,&.col-5,&.col-6,
                                &.col-7,&.col-7,&.col-9,
                                &.col-10,&.col-11,&.col-12 {
                                    flex: 0 0 auto;
                                    width: 100%;
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}
