.password-rules {
    margin: 15px 0;
    padding: 15px;
    border: 1px solid $color-lightgray-3;
    background-color: $color-white;

    @include rounded(4px);

    .password-rules-inner {
        h4 {
            margin: 0 0 15px;
            padding: 0 0 15px;
            display: block;
            color: $color-darkgray !important;
            font-size: 1.25rem;
            border-bottom: 1px solid $color-lightgray-3;
            }

        ul {
            &.password-rules-list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    &.pwd-rule {
                        position: relative;
                        margin: 0 0 5px;
                        padding: 0 0 0 20px;
                        display: block;
                        color: $color-darkgray;
                        font-size: 0.95rem;

                        &::before {
                            position: absolute;
                            top: 1px;
                            left: 0;
                            display: block;
                            width: 18px;
                            height: 18px;
                            font-family: $faregular;
                            font-size: 0.70rem;
                            line-height: 18px;
                            text-align: center;

                            content: '\f054';
                            }

                        &:last-child { margin-bottom: 0; }

                        &.pwd-min-char {}
                        &.pwd-num {}
                        &.pwd-upperalpha {}
                        &.pwd-loweralpha {}
                        &.pwd-specialchar {}
                    }
                }
            }
        }
    }
}
