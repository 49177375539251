.profile-topics-list {
    .profile-topics-list-inner {}

    ul {
        &.topics-list {
            margin: 0;
            padding: 0;
            }
    }

    li {
        &.topic-item {
            position: relative;
            margin: 0 0 8px;
            border: 1px solid #ccc;
            background-color: $color-white;

            @include boxshadow(0 0 10px rgba($color-black, 0.05));
            @include rounded(4px);

            &:last-child { margin-bottom: 0; }

            .topic-item-inner {}
        }
    }

    a {
        &.topic-item-header {
            padding: 0 30px 0 60px;
            display: block;

            @include transition(all 0.25s ease-in-out);

            &::before {
                position: absolute;
                top: 15px;
                right: 15px;
                display: block;
                width: 24px;
                height: 24px;
                font-family: $faregular;
                content: '\f054';
                line-height: 24px;
                text-align: center;

                @include transition(all 0.25s ease-in-out);
                }

            span {
                display: block;

                &.topic-item-color {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 45px;
                    height: 100%;

                    @include rounded(4px 0 0 4px);
                    @include transition(all 0.25s ease-in-out);
                    }

                &.topic-item-title {
                    padding: 15px 0;
                    color: #333;
                    font-size: 0.95rem;
                    font-weight: bold;
                    font-weight: 600;

                    @include transition(all 0.25s ease-in-out);
                    }
            }

            /* Hover State */
            /*
            &:hover {
                padding-left: 70px;

                span {
                    &.topic-item-color {
                        width: 55px;
                        }
                    &.topic-item-title {}
                }

                &::before {
                    right: 5px;
                    }
            }
            */
        }
    }

    .topic-item-content {
        display: none; /* Toggle */

        ul {
            &.activities-list {

            }
        }

        li {
            &.activity-item {
                padding: 10px 15px 10px 60px;
                border-top: 1px dotted #aaa;

                &::after {
                    clear: both;
                    margin: 0;
                    padding: 0;
                    display: table;
                    width: 0;
                    height: 0;
                    font-size: 0;
                    line-height: 0;
                    content: ' ';
                    overflow: hidden;
                    visibility: hidden;
                    *zoom: 1;
                    }

                .activity-title {
                    padding-top: 8px;
                    float: left;

                    span {
                        &.activity-color {}
                        &.activity-name {}
                    }
                }

                .activity-controls {
                    float: right;

                    .activity-select {
                        float: left;

                        select {
                            margin: 0;
                            padding: 0.50em 1.50em 0.50em 0.50em;
                            height: 35px;
                            cursor: pointer;
                            border: 1px solid #d1d5da;
                            background-color: $color-white;
                            background-position: right 50%;
                            background-repeat: no-repeat;

                            @include rounded(2px);
                            }
                    }

                    .activity-button-container {
                        padding-left: 15px;
                        float: left;

                        button {
                            &.btn {
                                margin-right: 15px;
                                color: #333; /*#4d97c1;*/
                                font-weight: normal;
                                font-weight: 400;
                                border-color: #ccc; /*rgba(77, 151, 193, 0.20);*/
                                background-color: #efefef; /*rgba(77, 151, 193, 0.20);*/

                                i {
                                    @include transition(color 0.10s ease-in-out);
                                    }

                                /* Hover State */
                                &:hover {
                                    color: $color-white;
                                    border-color: #76bc43; /*rgba(77, 151, 193, 0.75);*/
                                    background-color: #76bc43; /*rgba(77, 151, 193, 0.75);*/
                                    }

                                &:last-child { margin-right: 0; }

                                &[disabled] {
                                    color: #222;
                                    cursor: default;
                                    border-color: #aaa;
                                    background-color: #ddd;

                                    &:hover {
                                        border-color: #aaa;
                                        background-color: #ddd;
                                        }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* Active State */
    li {
        &.topic-item {
            &.active {

                a {
                    &.topic-item-header {
                        &::before {
                            @include transform(rotate(90deg));
                            }
                    }
                }

                .topic-item-content {
                    display: block; /* Toggle */
                    }
            }
        }
    }
}

.modal-topic-qr-view {
    .modal-content {
        .modal-header {
            .modal-title {
                line-height: 1.2;
                padding: 12px 0;

                #qr_code_activity_name {
                    font-weight: bold;
                    }
            }
        }

        .modal-body {
            .qr_code_container {
                text-align: center;

                img {
                    margin: auto;
                    }
            }
        }
    }
}


.topic-item-menu {
    overflow: hidden;
    background-color: #f1f1f1;
    margin-left: 45px;
    font-size: 14px;
    border-top: 1px dotted #aaa;

    a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;

        &:hover {}
    }

    .icon {
        display: none;
        }

    .topic-item-submenu {
        float: left;
        overflow: hidden;

        .dropbtn {
            border: none;
            outline: none;
            color: #333;
            padding: 14px 16px;
            background-color: inherit;
            font-family: inherit;
            margin: 0;
        }

        .topic-item-submenu-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0 8px 16px 0 rgba($color-black,0.20);
            z-index: 1;

            a {
                float: none;
                color: #333;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                text-align: left;
                
                /* Hover State */
                &:hover {
                    background-color: #ddd;
                    color: $color-black;
                    }
            }
        }

        &:hover {
            .dropbtn {}

            .topic-item-submenu-content {
                display: block;
                }
        }
    }
}

@media screen and (max-width: 600px) {
    .topic-item-menu {
        a {
            .icon {
                float: right;
                display: block;
                }

            &:not(:first-child) {
                display: none;
                }
        }

        .topic-item-submenu {
            .dropbtn {
                display: none;
                }
        }
    }

    .topic-item-menu {
        &.responsive {
            position: relative;

            .icon {
                position: absolute;
                right: 0;
                top: 0;
                }

            a {
                float: none;
                display: block;
                text-align: left;
                }

            .topic-item-submenu {
                float: none;

                .topic-item-submenu-content {
                    position: relative;
                    }

                .dropbtn {
                    display: block;
                    width: 100%;
                    text-align: left;
                    }
            }
        }
    }
}


/* Media Queries */
/* 1250px */
@media screen and (max-width: 1250px){
    .profile-topics-list {
        .topic-item-content {

            li {
                &.activity-item {

                    .activity-controls {

                        .activity-select {
                            select { max-width: 200px; }
                        }
                    }
                }
            }
        }
    }
}

/* 1100px */
@media screen and (max-width: 1100px){
    .profile-topics-list {
        .topic-item-content {

            li {
                &.activity-item {

                    .activity-title {
                        float: none;
                        }

                    .activity-controls {
                        padding-top: 10px;
                        float: none;

                        .activity-select {
                            float: left;
                            }

                        .activity-button-container {
                            float: left;

                            button {
                                &.btn {}
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 860px */
@media screen and (max-width: 860px){
    .profile-topics-list {
        .topic-item-content {

            li {
                &.activity-item {

                    .activity-title {}

                    .activity-controls {

                        .activity-select {
                            float: none;

                            select {
                                width: 100%;
                                max-width: 100%;
                                }
                        }

                        .activity-button-container {
                            padding-top: 10px;
                            padding-left: 0;
                            float: none;

                            button {
                                &.btn {
                                    margin: 10px 1.5% 0;
                                    display: block;
                                    float: left;
                                    width: 29.999999%;
                                    max-width: 29.999999%;

                                    span {
                                        &.dn-ns { display: inline; }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 400px */
@media screen and (max-width: 400px){
    .profile-topics-list {
        .topic-item-content {

            li {
                &.activity-item {

                    .activity-controls {

                        .activity-button-container {
                            padding-top: 0;
                            }
                    }
                }
            }
        }
    }
}

/* 350px */
@media screen and (max-width: 350px){
    .profile-topics-list {
        .topic-item-content {

            li {
                &.activity-item {

                    .activity-controls {

                        .activity-button-container {

                            button {
                                &.btn {
                                    i,
                                    span.dn-ns {
                                        margin: 0 auto;
                                        display: block;
                                        text-align: center;
                                        }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
