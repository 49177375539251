.api-container {
  .authorization_token_generation_container {
    .auth_token_success {
      overflow: auto;
      margin: 20px auto;

      .copy_authorization_token_container {
        text-align: right;

        .copy_authorization_token {
          margin-bottom: 10px;
        }
      }

      .token_value {
        word-wrap: break-word;
      }
    }

    .auth_token_error {
      overflow: auto;
      margin: 20px auto;
    }

    .existing-tokens-container {
      margin-top: 20px;

      i.fa-times {
        color: #dc3545;
      }

      i.fa-check {
        color: $color-green;
      }
    }
  }
}