.modal {

    /* Terms and Conditions Modal */
    &.modal-terms-and-conditions {
        backdrop-filter: blur(3px);

        .modal-dialog {
            .modal-content {
                @include rounded(5px);
                @include boxshadow(0 0 50px rgba($color-black,0.20));

                .modal-header {
                    background-color: $color-green;

                    @include rounded(5px 5px 0 0 !important);

                    h5 {
                        &.modal-title {}
                    }
                }

                .modal-body {
                    .terms-and-conditions-text {
                        padding: 0 15px;
                        height: auto;
                        max-height: 300px;
                        overflow: scroll;
                        overflow-x: hidden;
                        overflow-y: scroll;

                        h1,h2,h3,h4,h5,h6,p,ol,ul,li {
                            margin: 0;
                            padding: 0;
                            }

                        h1 {
                            &:not(:first-child){
                                margin-top: 20px;
                                padding-top: 30px;
                                border-top: 1px solid $color-charcoal;
                                }
                        }
                        h2 {
                            padding: 15px 0 5px;
                            font-size: 1.25rem;
                            font-weight: bold;
                            line-height: 1.75rem;
                            }
                        h3 {}
                        h4 {}
                        h5 {}
                        h6 {}

                        p {
                            padding: 10px 0;
                            font-size: 0.95rem;
                            line-height: 1.45rem;
                            }

                        li {
                            padding: 4px 0 4px 10px;
                            display: list-item;
                            font-size: 0.95rem;
                            line-height: 1.45rem;
                            }

                        ol {
                            margin: 0 0 0 25px;
                            padding: 10px 0;

                            > li {
                                list-style-type: upper-alpha;

                                > ol {
                                    > li {
                                        list-style-type: lower-roman;
                                        }
                                }
                            }
                        }

                        ul {
                            margin: 0 0 0 25px;
                            padding: 10px 0;

                            > li {
                                list-style-type: disc;
                                }
                        }

                    }
                }

                .modal-footer {}
            }
        }

        /* Modal Survey */
        &.model-survey {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .activity-survey-text {
                            ol {
                                &.survey-questions-list {
                                    margin: 0;
                                    padding: 0;
                                    list-style-type: none;

                                    li {
                                        padding: 0 0 15px;

                                        &:last-child {
                                            padding-bottom: 0;
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.modal-topic-activity-change {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        max-height: 350px;
                        overflow-y: scroll;
                        overflow-x: hidden;

                        a {
                            &.change_chart_topic_activity_trigger {
                                &.active {
                                    color: $color-lightgray-2;
                                    cursor: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
