.default-avatar {
    display: inline-block;
    color: $color-white;
    font-weight: 600;
    text-align: center;
    }

.user-list {
    .search-container {
        right: 10px;
        }

    .user-list-item {
        border-bottom: 1px solid $color-lightgray-2;

        &:last-child {
            border-bottom: 0;
            }

        /* Hover State */
        &:hover {
            background-color: #edeff0;
            }

        ._item {
            padding: 10px;
            display: inline-block;
            width: 100%;
            }

        a {
            color: $color-darkgray;
            text-decoration: none;

            /* Hover State */
            &:hover {
                color: $color-darkgray;
                background-color: transparent;
                }
        }
    }
}

// Reminder
.more-actions {
    .dots {
        padding: 0 8px;
        display: inherit;
        cursor: pointer;

        @include rounded(4px);

        i {
            color: #8c8c8c;
            }

        /* Hover/Focus States */
        &:hover,
        &:focus {
            i {
                color: #666;
                }
        }
    }
}

// Tags
.tags {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
        float: left;
        }
}

.tag {
    position: relative;
    margin: 0 10px 10px 0;
    padding: 0 20px 0 23px;
    display: inline-block;
    height: 24px;
    color: #999;
    line-height: 24px;
    text-decoration: none;
    background: $color-lightgray-2;

    @include rounded(3px);
    @include transition(color 0.20s);

    &::before {
        position: absolute;
        top: 8px;
        left: 10px;
        width: 6px;
        height: 6px;
        content: '';
        background: $color-white;

        @include rounded(10px);
        @include boxshadow(inset 0 1px rgba($color-black, 0.25));
        }

    /* Hover State */
    &:hover {
        color: #638421;
        background-color: #cde69c;
        }
}

.edit-tag-link {
    height: 24px;
    line-height: 24px;
    }

.list-group-item-action {
    i {
        margin-top: 4px;
        }
}

.social-icons {
    display: inline-block;
    text-align: center;
    }

/* User Import */
.import_users_list_container {

    /* Instructions */
    .instructions {
        p {}
    }

    /* Top Buttons */
    .import-button {
        a {
            i {
                padding-right: 5px;
                font-style: normal;
                }
        }
    }

    /* Import Progress */
    #import-progressbar {
        padding: 20px 0 0;

        .progress {
            .progress-bar {
                background-color: $color-green;

                &.progress-bar-striped {
                    &.active {}
                }
            }
        }
    }

    /* Import Messages */
    .import-messages {
        .import-success-messages {}
        .alert-success {
            border-color: rgba($color-green,0.75);
            background-color: rgba($color-green,0.20);
            }
    }

    /* Created Users Grid */
    .created-users-grid {
        .created-users-grid-inner {
            padding: 15px;
            border: 1px solid $color-lightgray-3;

            @include rounded(4px);
            }

        h4 {
            margin: 0 0 10px;
            padding: 0 0 10px;
            font-size: 1.35rem;
            font-weight: bold;
            border-bottom: 1px solid $color-lightgray-3;
            }

        .created-users-notice {
            padding: 0;

            p {
                font-size: 1.00rem;
                }
        }

        .created-users-buttons {
            a {
                i {
                    padding-right: 5px;
                    font-style: normal;
                    }
            }
        }

        table {
            &.table {
                &.created_users {
                    margin: 25px 0;
                    }
            }
        }
    }
}
