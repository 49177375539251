.header-search-input {
    min-width: 288px;
}

.header-search-results {
    top: 100%;
    z-index: 10;
    width: 100%;

    a {
        color: inherit;
        text-decoration: none;
        vertical-align: middle;
        background: transparent;

        span {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        /* Hover State */
        &:hover {
            color: inherit;
            background: inherit;
        }
    }
}

.header-search-result {
    border-bottom: 1px solid #eee;

    @include boxshadow(0 2px 3px 0 rgba(0, 0, 0, 0.3));

    /* Hover State */
    &:hover {
        background-color: #edeff0 !important;
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        vertical-align: middle;
        background: transparent;

        span {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }

    .has-relation-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -8px;
        color: #4dadf7;
    }

    &:last-child {
        border-bottom: initial;
    }
}

/* Media Queries */
/* 767px */

@media (max-width: 767px) {
    .header-search-input {
        min-width: 100%;
    }
}



/* Profile Header */
