body {
    &.profile-content-body {
        #app {
            .profile-main {

                /* Tabs */
                .tabbable-custom {
                    &.tabbable-tabdrop {
                        ul {
                            &.nav {
                                &.nav-tabs {
                                    margin: 0 0 30px;
                                    padding: 0;
                                    list-style-type: none;
                                    border-bottom: 1px solid #d5d5d5;

                                    &::after {
                                        clear: both;
                                        margin: 0;
                                        padding: 0;
                                        display: table;
                                        width: 0;
                                        height: 0;
                                        font-size: 0;
                                        line-height: 0;
                                        content: ' ';
                                        visibility: hidden;
                                        overflow: hidden;
                                        *zoom: 1;
                                        }

                                    > li {
                                        &.nav-item {
                                            margin: 0 5px 0 0;
                                            padding: 0;
                                            display: block;
                                            float: left;
                                            border: 0 none;
                                            background-color: transparent;

                                            a {
                                                &.nav-link {
                                                    position: relative;
                                                    margin: 0;
                                                    padding: 8px !important;
                                                    display: block;
                                                    color: #333;
                                                    border: 1px solid #d5d5d5;
                                                    border-width: 1px 1px 0;

                                                    @include rounded(3px 3px 0 0);
                                                    @include transition(all 0.10s ease-in-out);

                                                    /* Hover State */
                                                    &:hover {
                                                        background-color: #efefef;
                                                        }

                                                    /* Active State */
                                                    &.active {
                                                        color: $color-white;
                                                        border-color: $color-green;
                                                        background-color: $color-green;
                                                        }

                                                    &.errored-tab {
                                                        background-color: #dc3545;
                                                        color: $color-white;
                                                        }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* 975px */
@media screen and (max-width: 975px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {
                    .tabbable-custom {
                        &.tabbable-tabdrop {
                            ul {
                                &.nav {
                                    &.nav-tabs {
                                        border-bottom-width: 0;

                                        > li {
                                            &.nav-item {
                                                margin: 0 0 5px;
                                                float: none;
                                                width: 100%;

                                                &:last-child { margin-bottom: 0; }

                                                a {
                                                    &.nav-link {
                                                        border-width: 1px;

                                                        @include rounded(3px);
                                                        }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 650px */
@media screen and (max-width: 650px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {

                    /* Tabs */
                    .tabbable-custom {
                        &.tabbable-tabdrop {
                            ul {
                                &.nav {
                                    &.nav-tabs {
                                        border-bottom-width: 0;

                                        > li {
                                            &.nav-item {
                                                margin: 0 5px 5px 0;

                                                a {
                                                    &.nav-link {
                                                        border-width: 1px;

                                                        @include rounded(3px);
                                                        }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 500px */
@media screen and (max-width: 500px){
    body {
        &.profile-content-body {
            #app {
                .profile-main {

                    /* Tabs */
                    .tabbable-custom {
                        &.tabbable-tabdrop {
                            ul {
                                &.nav {
                                    &.nav-tabs {
                                        > li {
                                            &.nav-item {
                                                margin: 0 0 5px;
                                                float: none;
                                                width: 100%;
                                                }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
