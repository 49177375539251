.checkbox_collection {
    margin: 5px 0 0;
    padding: 10px;
    height: auto;
    max-height: 150px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;

    label {
        position: relative;
        margin: 0 0 5px;
        padding: 0 0 0 22px;
        display: block;
        font-size: 0.95rem;
        font-weight: normal;
        font-weight: 400;
        line-height: 1;
        cursor: pointer;

        input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;

            @include opacity(0.000001);

            + span {
                display: block;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 15px;
                    height: 15px;
                    color: $color-white;
                    font-family: $fasolid;
                    font-size: 0.60rem;
                    line-height: 15px;
                    text-align: center;
                    content: ' ';
                    border: 1px solid #d1d5da;

                    @include rounded(2px);
                    @include transition(
                        border-color 0.10s ease-in-out,
                        background-color 0.10s ease-in-out
                    );
                    }
            }

            /* Checked State */
            &:checked {
                + span {
                    &::before {
                        content: '\f00c';
                        border-color: $color-green;
                        background-color: $color-green;
                        }
                }
            }
        }
    }
}

.email_field_container_outer {
    .email_field_container {}
}

.username_field_container_outer {
    .username_field_container {}
    .username_field_checkbox_container {
        padding-top: 10px;

        input[type="checkbox"] {
            margin-right: 5px;
            }
    }
}

/* Track My Substance Use Grid under Profile */
.track-my-substance-use-container {
    margin-bottom: 20px;

    .track-my-substance-use-container-inner {
        .buttons-container {
            text-align: right;
            }

        #add_substance_trigger {}

        .heading {
            label {
                font-size: 18px;
                }
        }

        .track-my-substance-header-row {
            margin-top: 15px;
            margin-right: 0;
            margin-left: 0;
            padding-right: 45px;
            border: 1px solid #ebeef0;
            border-width: 1px 0;

            .col {
                padding-top: 12px;
                padding-bottom: 12px;
                color: #aaa;
                font-weight: bold;
                text-transform: uppercase;
                }
        }

        .track-my-substance-body-rows-container {
            padding: 0 0 10px;

            .track-my-substance-body-row {
                position: relative;
                margin-top: 15px;
                padding-right: 45px;

                label {
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    margin: -1px 0 0 -1px;
                    display: block;
                    width: 1px;
                    height: 1px;
                    visibility: hidden;
                    }

                select, input {
                    max-width: 99%;
                    }

                input {
                    margin-top: 2px;
                    }

                .remove_substance_row {
                    position: absolute;
                    top: 2px;
                    right: 15px;
                    z-index: 2;
                    padding: 0;
                    display: block;
                    width: 35px;
                    height: 35px;
                    color: #333;
                    font-size: 0.95rem;
                    cursor: pointer;
                    line-height: 35px;
                    text-align: center;
                    border: 1px solid #ccc;
                    background-color: $color-white;

                    @include rounded(5px);
                    @include transition(
                        color 0.20s ease-in-out,
                        border-color 0.20s ease-in-out,
                        background-color 0.20s ease-in-out
                    );

                    /* Hover State */
                    &:hover {
                        color: $color-white;
                        border-color: #960000;
                        background-color: #960000;
                        }
                }
            }
        }
    }
}

/* Media Queries */
/* 650px */
@media screen and (max-width: 650px) {
    #setting-form {
        .track-my-substance-header-row {
            display: none;
            }

        .row {
            &.track-my-substance-body-row {
                padding-bottom: 15px;
                border-bottom: 1px dashed #aaa;

                label {
                    top: 0;
                    left: 10px;
                    margin: 0;
                    display: block;
                    width: 135px;
                    height: auto;
                    line-height: 35px;
                    text-overflow: ellipsis;
                    visibility: visible;
                    overflow: hidden;
                    white-space: pre;
                    }

                .col {
                    position: relative;
                    margin-bottom: 5px;
                    padding-left: 155px;
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%;
                    }

                .remove_substance_row {
                    top: 27px;
                    }
            }
        }
    }

}

/* 425px */
@media screen and (max-width: 425px) {
    #setting-form {
        .track-my-substance-header-row {
            display: none;
            }

        .row {
            &.track-my-substance-body-row {
                label {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0 0 5px;
                    width: auto;
                    height: auto;
                    line-height: 22px;
                    }

                .col {
                    padding-left: 0;
                    }
            }
        }
    }

}
