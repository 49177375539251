.authentication-body {
    overflow: hidden;
    background-color: $color-darkgray;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .auth-container {

        .container {
            margin: 0 auto;
            padding: 30px 15px;
            width: 100%;
            max-width: 500px;
            }

        .auth-wrapper-outer {
            margin: 0;
            padding: 30px;
            background-color: $color-white;

            @include rounded(10px);
            }

        .auth-wrapper-inner {}

        .auth-form {}

        /* Branding */
        .branding-container {
            margin: 0 auto 30px;
            padding: 0;
            height: auto;
            text-align: center;

            > a {}

            &.site-logo {
                > a {
                    &.logo {
                        display: inline-block;
                        width: 80px;
                        height: auto;
                        }
                }
            }

            &.site-title {
                > a {}
            }
        }

        /* Form Structure */
        .form-outer {
            .form-inner {

                p {
                    &.login-instructions {
                        padding: 0 0 20px;
                        font-size: 1.15rem;
                        text-align: center;
                        }
                }

                h4 {
                    margin: 0;
                    padding: 0 0 30px;
                    text-align: center;
                    text-transform: capitalize;
                    }

                form {
                    .form-group {
                        input {
                            &.form-control {
                                margin: 0;
                                padding: 0 15px;
                                display: block;
                                height: 46px;
                                }
                        }
                    }
                }
            }
        }

        /* Remember Me/Forgot Password */
        .remember-and-password {
            position: relative;

            &::after {
                clear: both;
                margin: 0;
                padding: 0;
                display: table;
                width: 0;
                height: 0;
                font-size: 0;
                line-height: 0;
                content: ' ';
                overflow: hidden;
                visibility: hidden;
                *zoom: 1;
                }

            /* Remember Me */
            .remember-me {
                float: left;

                label {
                    position: relative;
                    margin: 0;
                    padding: 0 0 0 22px;
                    display: block;
                    font-weight: normal;
                    font-weight: 400;
                    line-height: 1;
                    cursor: pointer;

                    input[type="checkbox"] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;

                        @include opacity(0.000001);

                        + span {
                            display: block;

                            &::before {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: block;
                                width: 15px;
                                height: 15px;
                                color: $color-white;
                                font-family: $fasolid;
                                font-size: 0.60rem;
                                line-height: 15px;
                                text-align: center;
                                content: ' ';
                                border: 1px solid #d1d5da;

                                @include rounded(2px);
                                @include transition(
                                    border-color 0.10s ease-in-out,
                                    background-color 0.10s ease-in-out
                                );
                                }
                        }

                        /* Checked State */
                        &:checked {
                            + span {
                                &::before {
                                    content: '\f00c';
                                    border-color: $color-green;
                                    background-color: $color-green;
                                    }
                            }
                        }
                    }
                }
            }

            /* Forgot Password */
            a {
                &.forgot-password {
                    float: right;
                    margin: 0;
                    padding: 0;
                    color: $color-darkgray;
                    font-size: 0.85rem;
                    display: block;
                    line-height: 1;

                    @include transition(color 0.10s ease-in-out);

                    /* Hover State */
                    &:hover {
                        color: $color-green;
                        text-decoration: none;
                        }
                }
            }
        }

        /* Submit Button */
        .submit-button {
            padding: 15px 0 0;

            button {
                margin: 0;
                padding: 0 10px;
                display: block;
                width: 100%;
                height: 50px;
                color: $color-white;
                font-size: 1.00rem;
                line-height: 1;
                border: 1px solid $color-green;
                background-color: $color-green;

                @include rounded(5px);
                @include transition(
                    border-color 0.10s ease-in-out,
                    background-color 0.10s ease-in-out,
                    opacity-color 0.10s ease-in-out
                );

                /* Hover State */
                &:hover {
                    @include opacity(0.70);
                    }
            }
        }

        /* Return to Login Page */
        .back-to-login {
            a {
                position: relative;
                padding: 0 0 0 20px;
                display: block;
                color: $color-darkgray;

                @include transition(color 0.10s ease-in-out);

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin: -6.5px 0 0;
                    display: block;
                    width: 14px;
                    height: 14px;
                    font-family: $fasolid;
                    line-height: 14px;
                    text-align: center;
                    content: '\f053';
                    }

                /* Hover State */
                &:hover {
                    color: $color-green;
                    text-decoration: none;
                    }
            }
        }

        /* Form Footer Links */
        ul {
            &.auth-links-list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                &::after {
                    clear: both;
                    margin: 0;
                    padding: 0;
                    display: table;
                    width: 0;
                    height: 0;
                    font-size: 0;
                    line-height: 0;
                    content: ' ';
                    overflow: hidden;
                    visibility: hidden;
                    *zoom: 1;
                    }

                li {
                    position: relative;
                    margin: 0 10px 0 0;
                    padding: 0 10px 0 0;
                    display: block;
                    float: left;

                    a {
                        color: $color-darkgray;

                        @include transition(color 0.10s ease-in-out);

                        /* Hover State */
                        &:hover {
                            color: $color-green;
                            text-decoration: none;
                            }
                    }

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin: -6px 0 0;
                        display: block;
                        width: 1px;
                        height: 12px;
                        content: ' ';
                        background-color: $color-darkgray;
                        }

                    &:last-child {
                        margin-right: 0;
                        padding-right: 0;

                        &::after { display: none; }
                    }
                }
            }
        }
    }
}
