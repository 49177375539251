
.dashboard-widget {

    /* Sticky Widget on Profile Main Dashboard Page */
    &.dashboard-widget-top {
        margin: 10px;
        border: 1px solid $color-lightgray-3;
        background-color: $color-white;

        @include rounded(4px);

        h4 {
            &.dashboard-widget-title {
                position: relative;
                margin: 0;
                padding: 8px 15px;
                display: block;
                color: $color-darkgray;
                font-size: 1.15rem;
                line-height: 1.65rem;
                border-bottom: 1px solid $color-lightgray-3;
                }
        }

        .dashboard-widget-content {
            padding: 15px;
            /* border-top: 1px solid $color-lightgray-3; */

            &.no-padding { padding: 0; }
        }

        table {
            &.table {
                &.dashboard-widget-table {
                    tr {
                        &:last-child {
                            td {
                                border-bottom-width: 0;
                                }
                        }
                    }
                }
            }
        }
    }

    .overflow-auto {
        height: 280px;
        overflow: auto;
        }

    table {
        &.table {
            &.dashboard-widget-table {
                tr {
                    td {
                        .username-container {
                            min-width: 110px;

                            i {
                                font-size: 18px;
                                }
                        }

                        .activity_date_started_container {
                            width: 100px;
                            }

                        .action-report-cell {
                            i {
                                font-size: 18px;
                                }
                        }
                    }
                }
            }
        }
    }

    &.dashboard_widget_my_informations {
        h4 {
            &.dashboard-widget-title {
                &::before {
                    padding-right: 8px;
                    font-family: $faregular;

                    /* https://fontawesome.com/icons/address-card?s=regular */
                    content: '\f2bb';
                    }
            }
        }
    }
}

.entry-milestone {
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        border: 1px solid #eff1f3;
        position: absolute;
        top: 0;
        left: 24px;
        z-index: -1;
        }

    .milestone {
        margin-left: -30px;

        .icon {
            background-color: $color-white;
            color: #c6cbd1;
            display: inline-block;
            fill: currentColor;
            vertical-align: text-bottom;
            }
    }
}

.entry-list {
    .entry-item {
        & > span {
            content: "";
            display: block;
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: -29px;

            &:before, &:after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #fff;
                border: 2px solid #ccc;
                position: absolute;
                left: -5px;
                }

            &:before {
                top: -10px;
                }

            &:after {
                top: 95%;
                }
        }
    }
}

.btn-primary {
    background: #4d97c1;
    border-color: #2d8ec5;

    &:hover {
        background: #3995ca;
        border-color: #3995ca;
        }
}

.nav {
    .nav-item {
        a {
            padding-left: 0;
            }
    }
}

.italic {
    font-style: italic;
    }

/* Dashboard Info Box */
.dashboard-info-box {
    margin: 10px;
    padding: 15px;
    border: 1px solid $color-lightgray-3;
    background-color: $color-white;

    @include rounded(4px);
    }

/* Dashboard Grid Stack */
.grid-stack {
    > .grid-stack-item {
        @include rounded(4px);

        /*
        > .ui-resizable-se {
            top: 22px !important;
            right: 30px !important;
            bottom: auto !important;
            left: auto !important;
            }
        */

        .grid-stack-item-content {
            border: 1px solid $color-lightgray-3;
            background-color: $color-white;

            @include rounded(4px);
            }

        h4 {
            &.dashboard-widget-title {
                position: relative;
                margin: 0;
                padding: 8px 15px;
                display: block;
                color: $color-darkgray;
                font-size: 1.15rem;
                line-height: 1.65rem;
                border-bottom: 1px solid $color-lightgray-3;
                }
        }

        .dashboard-widget-content {
            padding: 15px;
            /* border-top: 1px solid $color-lightgray-3; */

            &.no-padding { padding: 0; }
        }

        /* Floating Values */
        .dashboard-widget {
            &.floating-values {
                position: relative;
                padding-top: 43px;
                height: 100%;

                h4 {
                    &.dashboard-widget-title {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        width: 100%;
                        }
                }

                .dashboard-widget-content {
                    height: 100%;

                    table {
                        &.table {
                            &.dashboard-widget-table {
                                height: 100%;

                                thead {
                                    tr {
                                        th {
                                            height: 33px;
                                            text-align: center;
                                            }
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            font-size: 4.50rem;
                                            text-align: center;
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Tables */
        table {
            &.table {

                /* Default Table UI */
                &.dashboard-widget-table {
                    border: 0 none;

                    > thead,
                    > tbody {
                        th,
                        td {
                            padding-right: 15px !important;
                            padding-left: 15px !important;
                            }

                        td {
                            padding-top: 6px !important;
                            padding-bottom: 6px !important;
                            }
                    }

                    > thead {
                        th {
                            border: 1px solid $color-lightgray-3 !important;
                            border-width: 0 0 1px !important;
                            }
                    }

                    > tbody {
                        td {
                            border: 1px solid $color-lightgray-3;
                            border-width: 0 0 1px;

                            &.action-status-cell {
                                text-align: center;

                                a {
                                    &.btn-action {
                                        position: relative;
                                        padding: 5px 10px;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        color: $color-charcoal;
                                        text-align: center;
                                        border: 1px solid $color-charcoal;
                                        background-color: #fff;

                                        @include rounded(3px);
                                        @include transition(
                                            color 0.10s ease-in-out,
                                            background-color 0.10s ease-in-out
                                        );

                                        /*
                                        &::before {
                                            padding-right: 5px;
                                            font-family: $faregular;
                                            }

                                        / * Icon: Resume Activity * /
                                        &.resume-activity {
                                            &::before {

                                                / * https://fontawesome.com/v5/icons/arrow-right?s=regular * /
                                                content: '\f061';
                                                }
                                        }

                                        / * Icon: Start Activity * /
                                        &.start-activity {
                                            &::before {

                                                / * https://fontawesome.com/v5/icons/file-plus?s=regular * /
                                                content: '\f319';
                                                }
                                        }

                                        / * Icon: View Report * /
                                        &.view-report {
                                            &::before {

                                                / * https://fontawesome.com/v5/icons/file-chart-line?s=regular * /
                                                content: '\f659';
                                                }
                                        }
                                        */

                                        /* Hover State */
                                        &:hover {
                                            color: $color-white;
                                            background-color: $color-green;
                                            }
                                    }
                                }
                            }
                        }

                        tr {

                            /* System Activity Rows */
                            &.system_activity_row {
                                > td, > th {
                                    padding: 0 !important;
                                    border-bottom-width: 0 !important;
                                    }

                                [class*="col-"] {
                                    text-align: center;
                                    }

                                [class="col-12"] {
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    font-size: 0.90rem;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    border-bottom: 1px solid $color-lightgray-3;
                                    background-color: rgba($color-black,0.05);
                                    }
                            }

                            &:last-child {
                                > td {
                                    border-width: 0;
                                    }
                            }
                        }
                    }

                    /* Links */
                    a {
                        // &.user-info {
                            color: $color-charcoal;

                            /* Hover State */
                            &:hover {
                                color: $color-green;
                                }
                        // }
                    }

                    /* Status */
                    .status {
                        &::before {
                            font-family: $falight;
                            font-size: 1.35rem;
                            line-height: 1;
                            }

                        &.completed {
                            &::before {
                                /* https://fontawesome.com/v5.15/icons/check-square?style=light */
                                content: '\f14a';
                                }
                        }
                        &.incomplete {
                            &::before {
                                content: '\f144';
                            }
                        }
                        &.not-completed {
                            &::before {
                                /* https://fontawesome.com/v5.15/icons/times-square?style=light */
                                content: '\f2d3';
                                }
                        }
                    }

                    /** Survey Star */
                    .survey {
                        &::before {
                            font-family: $falight;
                            font-size: 1.35rem;
                            line-height: 1;
                            }

                        &.completed {
                            &::before {
                                font-family: $fasolid;

                                /* https://fontawesome.com/v5.15/icons/check-square?style=solid */
                                content: '\f005';
                                color: #ffd60b;
                                }
                        }
                        &.pending {
                            cursor: pointer;
                            &::before {
                                /* https://fontawesome.com/v5.15/icons/times-square?style=light */
                                content: '\f005';
                                }
                        }
                    }
                }

                /* Available Topics Widget */
                &.available-topics-table {
                    > tbody {

                        /*
                        td {
                            border: 1px solid $color-lightgray-3;
                            border-width: 0 1px 1px 0;
                            }

                        tr {
                            &:last-child {
                                td {
                                    border-width: 0 1px 1px 0;
                                    }
                            }

                            td {
                                &:last-child {
                                    border-right-width: 0;
                                    }
                            }
                        }
                        */

                        tr {
                            td {
                                border-width: 0 !important;
                                }

                            /* Odd Rows */
                            &.odd {
                                td {
                                    background-color: $color-lightgray-2;
                                    background-color: rgba($color-lightgray-2,0.60);
                                    }
                            }

                            /* Even Rows */
                            &.even {
                                td {
                                    background-color: $color-white;
                                    }
                            }
                        }
                    }
                }
            }
        }

        /* Draggable */
        &.ui-draggable {
            h4 {
                &.dashboard-widget-title {
                    cursor: grab;

                    /* Active State */
                    &:active {
                        cursor: grabbing;
                        }

                    &::before,
                    &::after {
                        position: absolute;
                        top: 3px;
                        display: block;
                        width: 11px;
                        font-family: $fasolid;
                        font-size: 0.70rem;
                        line-height: 15px;
                        content: '\f58d';
                        text-align: center;

                        @include opacity(0.50);
                        }

                    &::before { left: 50%; }
                    &::after { right: 50%; }
                }
            }
        }

        /* Topic Colors */
        span {
            &.gridstack-str-lbl {
                position: relative;
                padding-left: 25px;
                display: block;

                .gridstack-topic-color {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -7px;
                    display: block;
                    width: 14px;
                    height: 14px;
                    }
            }
        }

        /* Radio Toggles */
        .widget-table-radio-toggles {
            text-align: center;

            label {
                position: relative;
                margin-right: 15px;
                margin-left: 15px;
                display: inline-block;
                font-size: 0.85rem;
                font-weight: normal;
                cursor: pointer;
                text-transform: none;

                span {
                    position: relative;
                    padding: 0 0 0 20px;
                    display: block;

                    @include transition(color 0.10s ease-in-out);

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 0;
                        display: block;
                        width: 16px;
                        height: 16px;
                        content: ' ';
                        border: 2px solid $color-darkgray;
                        background-color: $color-white;

                        @include transition(all 0.10s ease-in-out);
                        @include rounded(50%);
                        }
                }

                /* Hover State */
                &:hover {
                    span {
                        color: $color-darkgray;
                        }
                }

                input {
                    &[type="radio"] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        border: 0 none;
                        background-color: transparent;

                        @include opacity(0.00001);

                        /* Checked State */
                        &:checked {
                            + span {
                                color: $color-darkgray;

                                &::before {
                                    border-color: $color-green;
                                    background-color: $color-green;
                                    }
                            }
                        }
                    }
                }
            }
        }

        /* System Activity Items */
        .sysactivity_verticalcenter {
            align-items: center;
            justify-content: center;
            }

        .sysactivity_item {
            padding-top: 5px;
            padding-bottom: 5px;

            &.sysactivity_center {
                text-align: center;
                }

            .sysactivity_title {
                padding-bottom: 5px;
                font-size: 0.70rem;
                font-weight: bold;
                }
            .sysactivity_val {
                padding-top: 8px;
                padding-bottom: 8px;
                font-size: 1.85rem;
                font-weight: bold;
                }
            .sysactivity_subtitle {
                font-size: 0.70rem;
                }
        }

        /* Widget Filters */
        .dashboard-widget-filters {
            border-bottom: 1px solid $color-lightgray-3;

            .dashboard-widget-filter {
                padding: 5px 15px;

                .filter-label {
                    padding-right: 10px;
                    font-weight: bold;
                    font-weight: 600;
                    }

                .filter-field {
                    select {
                        border: 1px solid rgba($color-black,0.50);
                        background-color: $color-white;

                        @include rounded(3px);
                        @include transition(
                            color 0.10s ease-in-out,
                            border-color 0.10s ease-in-out
                        );

                        /* Hover and Focus States */
                        &:hover,
                        &:focus {
                            border-color: $color-green;
                            }
                    }
                }
            }
        }

    }
}

.dashboard-main {
    h4 {
        &.dashboard-widget-title {
            position: relative;
            margin: 0;
            padding: 8px 15px;
            display: block;
            color: $color-darkgray;
            font-size: 1.15rem;
            line-height: 1.65rem;
            border-bottom: 1px solid $color-lightgray-3;
        }
    }

    .dashboard-widget-content {
        padding: 15px;
        /* border-top: 1px solid $color-lightgray-3; */

        &.no-padding { padding: 0; }
    }

    /* Floating Values */
    .dashboard-widget {
        &.floating-values {
            position: relative;
            padding-top: 43px;
            height: 100%;

            h4 {
                &.dashboard-widget-title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                }
            }

            .dashboard-widget-content {
                height: 100%;

                table {
                    &.table {
                        &.dashboard-widget-table {
                            height: 100%;

                            thead {
                                tr {
                                    th {
                                        height: 33px;
                                        text-align: center;
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        font-size: 4.50rem;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* Tables */
    table {
        &.table {

            /* Default Table UI */
            &.dashboard-widget-table {
                border: 0 none;

                > thead,
                > tbody {
                    th,
                    td {
                        padding-right: 15px !important;
                        padding-left: 15px !important;
                    }

                    td {
                        padding-top: 6px !important;
                        padding-bottom: 6px !important;
                    }
                }

                > thead {
                    th {
                        border: 1px solid $color-lightgray-3 !important;
                        border-width: 0 0 1px !important;
                    }
                }

                > tbody {
                    td {
                        border: 1px solid $color-lightgray-3;
                        border-width: 0 0 1px;

                        &.action-status-cell {
                            text-align: center;

                            a {
                                &.btn-action {
                                    position: relative;
                                    padding: 5px 10px;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    color: $color-charcoal;
                                    text-align: center;
                                    border: 1px solid $color-charcoal;
                                    background-color: #fff;

                                    @include rounded(3px);
                                    @include transition(
                                                    color 0.10s ease-in-out,
                                                    background-color 0.10s ease-in-out
                                    );

                                    /*
                                    &::before {
                                        padding-right: 5px;
                                        font-family: $faregular;
                                        }

                                    / * Icon: Resume Activity * /
                                    &.resume-activity {
                                        &::before {

                                            / * https://fontawesome.com/v5/icons/arrow-right?s=regular * /
                                            content: '\f061';
                                            }
                                    }

                                    / * Icon: Start Activity * /
                                    &.start-activity {
                                        &::before {

                                            / * https://fontawesome.com/v5/icons/file-plus?s=regular * /
                                            content: '\f319';
                                            }
                                    }

                                    / * Icon: View Report * /
                                    &.view-report {
                                        &::before {

                                            / * https://fontawesome.com/v5/icons/file-chart-line?s=regular * /
                                            content: '\f659';
                                            }
                                    }
                                    */

                                    /* Hover State */
                                    &:hover {
                                        color: $color-white;
                                        background-color: $color-green;
                                    }
                                }
                            }
                        }
                    }

                    tr {

                        /* System Activity Rows */
                        &.system_activity_row {
                            > td, > th {
                                padding: 0 !important;
                                border-bottom-width: 0 !important;
                            }

                            [class*="col-"] {
                                text-align: center;
                            }

                            [class="col-12"] {
                                padding-top: 5px;
                                padding-bottom: 5px;
                                font-size: 0.90rem;
                                font-weight: bold;
                                text-transform: uppercase;
                                border-bottom: 1px solid $color-lightgray-3;
                                background-color: rgba($color-black,0.05);
                            }
                        }

                        &:last-child {
                            > td {
                                border-width: 0;
                            }
                        }
                    }
                }

                /* Links */
                a {
                    // &.user-info {
                    color: $color-charcoal;

                    /* Hover State */
                    &:hover {
                        color: $color-green;
                    }
                    // }
                }

                /* Status */
                .status {
                    &::before {
                        font-family: $falight;
                        font-size: 1.35rem;
                        line-height: 1;
                    }

                    &.completed {
                        &::before {
                            /* https://fontawesome.com/v5.15/icons/check-square?style=light */
                            content: '\f14a';
                        }
                    }
                    &.incomplete {
                        &::before {
                            content: '\f144';
                        }
                    }
                    &.not-completed {
                        &::before {
                            /* https://fontawesome.com/v5.15/icons/times-square?style=light */
                            content: '\f2d3';
                        }
                    }
                }

                /** Survey Star */
                .survey {
                    &::before {
                        font-family: $falight;
                        font-size: 1.35rem;
                        line-height: 1;
                    }

                    &.completed {
                        &::before {
                            font-family: $fasolid;

                            /* https://fontawesome.com/v5.15/icons/check-square?style=solid */
                            content: '\f005';
                            color: #ffd60b;
                        }
                    }
                    &.pending {
                        cursor: pointer;
                        &::before {
                            /* https://fontawesome.com/v5.15/icons/times-square?style=light */
                            content: '\f005';
                        }
                    }
                }

                .report_column {
                    .view-report {
                        &::before {
                            font-family: $falight;
                            font-size: 1.35rem;
                            line-height: 1;
                            content: '\f659';
                        }
                    }
                }
            }

            /* Available Topics Widget */
            &.available-topics-table {
                > tbody {

                    /*
                    td {
                        border: 1px solid $color-lightgray-3;
                        border-width: 0 1px 1px 0;
                        }

                    tr {
                        &:last-child {
                            td {
                                border-width: 0 1px 1px 0;
                                }
                        }

                        td {
                            &:last-child {
                                border-right-width: 0;
                                }
                        }
                    }
                    */

                    tr {
                        td {
                            border-width: 0 !important;
                        }

                        /* Odd Rows */
                        &.odd {
                            td {
                                background-color: $color-lightgray-2;
                                background-color: rgba($color-lightgray-2,0.60);
                            }
                        }

                        /* Even Rows */
                        &.even {
                            td {
                                background-color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Topic Colors */
    span {
        &.gridstack-str-lbl {
            position: relative;
            padding-left: 25px;
            display: block;

            .gridstack-topic-color {
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -7px;
                display: block;
                width: 14px;
                height: 14px;
            }
        }
    }

    /* Radio Toggles */
    .widget-table-radio-toggles {
        text-align: center;

        label {
            position: relative;
            margin-right: 15px;
            margin-left: 15px;
            display: inline-block;
            font-size: 0.85rem;
            font-weight: normal;
            cursor: pointer;
            text-transform: none;

            span {
                position: relative;
                padding: 0 0 0 20px;
                display: block;

                @include transition(color 0.10s ease-in-out);

                &::before {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: ' ';
                    border: 2px solid $color-darkgray;
                    background-color: $color-white;

                    @include transition(all 0.10s ease-in-out);
                    @include rounded(50%);
                }
            }

            /* Hover State */
            &:hover {
                span {
                    color: $color-darkgray;
                }
            }

            input {
                &[type="radio"] {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 0 none;
                    background-color: transparent;

                    @include opacity(0.00001);

                    /* Checked State */
                    &:checked {
                        + span {
                            color: $color-darkgray;

                            &::before {
                                border-color: $color-green;
                                background-color: $color-green;
                            }
                        }
                    }
                }
            }
        }
    }

    /* System Activity Items */
    .sysactivity_verticalcenter {
        align-items: center;
        justify-content: center;
    }

    .sysactivity_item {
        padding-top: 5px;
        padding-bottom: 5px;

        &.sysactivity_label {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            }

        &.sysactivity_center {
            text-align: center;
            }
        .sysactivity_title {
            padding-bottom: 5px;
            font-size: 0.70rem;
            font-weight: bold;
            }
        .sysactivity_val {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 1.85rem;
            font-weight: bold;
            }
        .sysactivity_subtitle {
            font-size: 0.70rem;
            }
    }

    /* Widget Filters */
    .dashboard-widget-filters {
        border-bottom: 1px solid $color-lightgray-3;

        .dashboard-widget-filter {
            padding: 5px 15px;

            .filter-label {
                padding-right: 10px;
                font-weight: bold;
                font-weight: 600;
            }

            .filter-field {
                select {
                    border: 1px solid rgba($color-black,0.50);
                    background-color: $color-white;

                    @include rounded(3px);
                    @include transition(
                                    color 0.10s ease-in-out,
                                    border-color 0.10s ease-in-out
                    );

                    /* Hover and Focus States */
                    &:hover,
                    &:focus {
                        border-color: $color-green;
                    }
                }
            }
        }
    }
}



/* Topic Colors */
span {
    &.table-topic-lbl {
        position: relative;
        padding-left: 25px;
        display: block;

        .table-topic-color {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -7px;
            display: block;
            width: 14px;
            height: 14px;
            }
    }
}



/* Media Queries */
/* 550px */
@media screen and (max-width: 550px){
    .grid-stack {
        > .grid-stack-item {

            /* Radio Toggles */
            .widget-table-radio-toggles {
                text-align: left;

                > label {
                    margin: 0 0 5px;
                    display: block;

                    &:last-child {
                        margin-bottom: 0;
                        }
                }
            }

            /* Profile Dashboard Widgets */
            .dashboard-widget {

                /*
                OK
                Widget Name: My Available Topics
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/available_topics_activities.blade.php
                */
                &.dashboard_widget_available_topics_activities {
                    table {
                        &.table.dashboard-widget-table {
                            thead { display: none; }
                            tbody {
                                tr {
                                    display: block;

                                    td {
                                        display: block;

                                        &:first-child   { padding-top: 15px !important; }
                                        &:last-child    { padding-bottom: 15px !important; }
                                    }
                                }
                            }
                        }
                    }
                }

                /*
                OK
                Widget Name: My Activity Progress
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/dashboard_widget_my_activity_progress.blade.php
                */
                &.dashboard_widget_my_activity_progress {

                }

                /*
                OK
                Widget Name: Activity Across Topics
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/dashboard_widget_activity_across_topics.blade.php
                */
                &.dashboard_widget_activity_across_topics {

                }

                /*
                OK
                Widget Name: Company Summary
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/company_summary.blade.php
                */
                &.dashboard_widget_company_summary {
                    .dashboard-widget-content {
                        table {
                            &.table {
                                &.dashboard-widget-table {
                                    thead { display: none; }
                                    tbody {
                                        tr {
                                            display: block;

                                            td {
                                                display: block;
                                                font-size: 1.00rem;
                                                text-align: left;
                                                border-bottom: 1px solid #ccc;

                                                &:last-child { border-width: 0; }

                                                &::after {
                                                    clear: both;
                                                    margin: 0;
                                                    padding: 0;
                                                    display: table;
                                                    width: 0;
                                                    height: 0;
                                                    font-size: 0;
                                                    line-height: 0;
                                                    content: ' ';
                                                    overflow: hidden;
                                                    visibility: hidden;
                                                    *zoom: 1;
                                                    }

                                                span {
                                                    position: static;
                                                    margin: 0;
                                                    padding: 0 5px 0 0;
                                                    display: block;
                                                    float: left;
                                                    width: 50%;
                                                    height: auto;
                                                    font-weight: bold;
                                                    }

                                                a {
                                                    margin: 0;
                                                    padding: 0 0 0 5px;
                                                    display: block;
                                                    float: left;
                                                    width: 50%;
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /*
                OK
                Widget Name: My Activity Summary
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/my_activities_count.blade.php
                */
                &.dashboard_widget_my_activities_count,

                /*
                OK
                Widget Name: User Activity Across System
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/system_activity.blade.php
                */
                &.dashboard_widget_system_activity {

                    table {
                        &.table {
                            &.dashboard-widget-table {
                                thead { display: none; }
                            }
                        }
                    }

                    .sysactivity_item {

                        &.col-1,&.col-2,&.col-3,
                        &.col-4,&.col-5,&.col-6,
                        &.col-7,&.col-8,&.col-9,
                        &.col-10,&.col-11 {
                            width: 100%;
                            border-bottom: 1px solid #ccc;
                            }

                        &:last-child {
                            border-width: 0;
                            }

                        &::after {
                            clear: both;
                            margin: 0;
                            padding: 0;
                            display: table;
                            width: 0;
                            height: 0;
                            font-size: 0;
                            line-height: 0;
                            content: ' ';
                            overflow: hidden;
                            visibility: hidden;
                            *zoom: 1;
                            }

                        .sysactivity_title,
                        .sysactivity_val,
                        .sysactivity_subtitle {
                            position: static;
                            margin: 0;
                            padding: 0 5px;
                            display: block;
                            float: left;
                            height: auto;
                            font-size: 0.90rem;
                            }
                            .sysactivity_title {
                                width: 40%;
                                text-align: right;
                                }
                            .sysactivity_val {
                                width: 20%;
                                text-align: center;
                                }
                            .sysactivity_subtitle {
                                width: 40%;
                                text-align: left;
                                }
                    }
                }

                /*
                Widget Name: My Recently Started Activities
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_started_myactivities.blade.php
                */
                &.dashboard_widget_recent_started_activities,

                /*
                Widget Name: Recently Started User Activities:
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_started_user_activities.blade.php
                */
                &.dashboard_widget_recent_started_user_activities,

                /*
                Widget Name: Recently Completed User Activities
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_completed_user_activities.blade.php
                */
                &.dashboard_widget_recent_completed_user_activities,

                /*
                OK
                Widget Name: Popular Activities
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/popular_activities.blade.php
                */
                &.dashboard_widget_popular_activities,

                /*
                Widget Name: Recent User Logins
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_user_logins.blade.php
                */
                &.dashboard_widget_recent_recent_user_logins {
                    .dashboard-widget-content {
                        table {
                            &.table {
                                &.dashboard-widget-table {
                                    thead {
                                        tr {
                                            &.tbl-headings { display: none; }
                                        }
                                    }

                                    tbody {
                                        tr {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: stretch;
                                            border-bottom: 1px solid #ccc;

                                            &:nth-child(2n) {
                                                background-color: rgba(238, 238, 238, 0.60);
                                                }

                                            &:last-child { border-width: 0; }

                                            td {
                                                flex: 1;
                                                text-align: left !important;
                                                border-width: 0;

                                                span {
                                                    &.col-lbl {
                                                        position: static;
                                                        margin: 0;
                                                        padding: 0 10px 0 0;
                                                        display: inline-flex;
                                                        width: 95px !important;
                                                        height: auto;
                                                        font-weight: bold;
                                                        }
                                                }

                                                .user-info {
                                                    margin: 0 !important;
                                                    padding: 0;
                                                    float: none;
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }
    }

    .dashboard-main {

        /* Radio Toggles */
        .widget-table-radio-toggles {
            text-align: left;

            > label {
                margin: 0 0 5px 15px;
                display: block;

                &:last-child {
                    margin-bottom: 0;
                    }
            }
        }

        /* Profile Dashboard Widgets */
        .dashboard-widget {

            /*
            OK
            Widget Name: My Available Topics
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/available_topics_activities.blade.php
            */
            &.dashboard_widget_available_topics_activities {
                table {
                    &.table.dashboard-widget-table {
                        thead { display: none; }
                        tbody {
                            tr {
                                display: block;

                                td {
                                    display: block;

                                    &:first-child   { padding-top: 15px !important; }
                                    &:last-child    { padding-bottom: 15px !important; }
                                }
                            }
                        }
                    }
                }
            }

            /*
            OK
            Widget Name: My Activity Progress
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/dashboard_widget_my_activity_progress.blade.php
            */
            &.dashboard_widget_my_activity_progress {

            }

            /*
            OK
            Widget Name: Activity Across Topics
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/dashboard_widget_activity_across_topics.blade.php
            */
            &.dashboard_widget_activity_across_topics {

            }

            /*
            OK
            Widget Name: Company Summary
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/company_summary.blade.php
            */
            &.dashboard_widget_company_summary {
                .dashboard-widget-content {
                    table {
                        &.table {
                            &.dashboard-widget-table {
                                thead { display: none; }
                                tbody {
                                    tr {
                                        display: block;

                                        td {
                                            display: block;
                                            font-size: 1.00rem;
                                            text-align: left;
                                            border-bottom: 1px solid #ccc;

                                            &:last-child { border-width: 0; }

                                            &::after {
                                                clear: both;
                                                margin: 0;
                                                padding: 0;
                                                display: table;
                                                width: 0;
                                                height: 0;
                                                font-size: 0;
                                                line-height: 0;
                                                content: ' ';
                                                overflow: hidden;
                                                visibility: hidden;
                                                *zoom: 1;
                                            }

                                            span {
                                                position: static;
                                                margin: 0;
                                                padding: 0 5px 0 0;
                                                display: block;
                                                float: left;
                                                width: 50%;
                                                height: auto;
                                                font-weight: bold;
                                            }

                                            a {
                                                margin: 0;
                                                padding: 0 0 0 5px;
                                                display: block;
                                                float: left;
                                                width: 50%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /*
            OK
            Widget Name: My Activity Summary
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/my_activities_count.blade.php
            */
            &.dashboard_widget_my_activities_count,

                /*
                OK
                Widget Name: User Activity Across System
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/system_activity.blade.php
                */
            &.dashboard_widget_system_activity {

                table {
                    &.table {
                        &.dashboard-widget-table {
                            thead { display: none; }
                        }
                    }
                }

                .sysactivity_item {

                    &.col-1,&.col-2,&.col-3,
                    &.col-4,&.col-5,&.col-6,
                    &.col-7,&.col-8,&.col-9,
                    &.col-10,&.col-11 {
                        width: 100%;
                        border-bottom: 1px solid #ccc;
                    }

                    &:last-child {
                        border-width: 0;
                    }

                    &::after {
                        clear: both;
                        margin: 0;
                        padding: 0;
                        display: table;
                        width: 0;
                        height: 0;
                        font-size: 0;
                        line-height: 0;
                        content: ' ';
                        overflow: hidden;
                        visibility: hidden;
                        *zoom: 1;
                    }

                    .sysactivity_title,
                    .sysactivity_val,
                    .sysactivity_subtitle {
                        position: static;
                        margin: 0;
                        padding: 0 5px;
                        display: block;
                        float: left;
                        height: auto;
                        font-size: 0.90rem;
                    }
                    .sysactivity_title {
                        width: 40%;
                        text-align: right;
                    }
                    .sysactivity_val {
                        width: 20%;
                        text-align: center;
                    }
                    .sysactivity_subtitle {
                        width: 40%;
                        text-align: left;
                    }
                }
            }

            /*
            Widget Name: My Recently Started Activities
            Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_started_myactivities.blade.php
            */
            &.dashboard_widget_recent_started_activities,

                /*
                Widget Name: Recently Started User Activities:
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_started_user_activities.blade.php
                */
            &.dashboard_widget_recent_started_user_activities,

                /*
                Widget Name: Recently Completed User Activities
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_completed_user_activities.blade.php
                */
            &.dashboard_widget_recent_completed_user_activities,

                /*
                OK
                Widget Name: Popular Activities
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/popular_activities.blade.php
                */
            &.dashboard_widget_popular_activities,

                /*
                Widget Name: Recent User Logins
                Path: platform/plugins/member-r1l/resources/views/dashboard/grid_block/recent_user_logins.blade.php
                */
            &.dashboard_widget_recent_recent_user_logins {
                .dashboard-widget-content {
                    table {
                        &.table {
                            &.dashboard-widget-table {
                                thead {
                                    tr {
                                        &.tbl-headings { display: none; }
                                    }
                                }

                                tbody {
                                    tr {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: stretch;
                                        border-bottom: 1px solid #ccc;

                                        &:nth-child(2n) {
                                            background-color: rgba(238, 238, 238, 0.60);
                                        }

                                        &:last-child { border-width: 0; }

                                        td {
                                            flex: 1;
                                            text-align: left !important;
                                            border-width: 0;

                                            span {
                                                &.col-lbl {
                                                    position: static;
                                                    margin: 0;
                                                    padding: 0 10px 0 0;
                                                    display: inline-flex;
                                                    width: 95px !important;
                                                    height: auto;
                                                    font-weight: bold;
                                                }
                                            }

                                            .user-info {
                                                margin: 0 !important;
                                                padding: 0;
                                                float: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}
