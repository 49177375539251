
/* Font Awesome Brands */
@font-face {
	font-family: 'Font Awesome 6 Brands';
	src:  url('../fonts/fontawesome/fa-brands-400.ttf?hu3sw6') format('truetype'),
		  url('../fonts/fontawesome/fa-brands-400.woff2?hu3sw6') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* Font Awesome Duotone */
@font-face {
	font-family: 'Font Awesome 6 Duotone';
	src:  url('../fonts/fontawesome/fa-duotone-900.ttf?hu3sw6') format('truetype'),
		  url('../fonts/fontawesome/fa-duotone-900.woff2?hu3sw6') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* Font Awesome (Thin) */
@font-face {
	font-family: 'Font Awesome 6 Thin';
	src:  url('../fonts/fontawesome/fa-thin-100.ttf?hu3sw5') format('truetype'),
		  url('../fonts/fontawesome/fa-thin-100.woff2?hu3sw5') format('woff');
	font-weight: 100;
	font-style: normal;
}

/* Font Awesome (Light) */
@font-face {
	font-family: 'Font Awesome 6 Light';
	src:  url('../fonts/fontawesome/fa-light-300.ttf?hu3sw5') format('truetype'),
		  url('../fonts/fontawesome/fa-light-300.woff2?hu3sw5') format('woff');
	font-weight: 300;
	font-style: normal;
}

/* Font Awesome (Regular) */
@font-face {
	font-family: 'Font Awesome 6 Regular';
	src:  url('../fonts/fontawesome/fa-regular-400.ttf?hu3sw4') format('truetype'),
		  url('../fonts/fontawesome/fa-regular-400.woff2?hu3sw4') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Font Awesome (Regular Sharp) */
@font-face {
	font-family: 'Font Awesome 6 Regular Sharp';
	src:  url('../fonts/fontawesome/fa-sharp-regular-400.ttf?hu3sw4') format('truetype'),
		  url('../fonts/fontawesome/fa-sharp-regular-400.woff2?hu3sw4') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Font Awesome (Solid) */
@font-face {
	font-family: 'Font Awesome 6 Solid';
	src:  url('../fonts/fontawesome/fa-solid-900.ttf?hu3sw3') format('truetype'),
		  url('../fonts/fontawesome/fa-solid-900.woff2?hu3sw3') format('woff');
	font-weight: 900;
	font-style: normal;
}

/* Font Awesome (Solid Sharp) */
@font-face {
	font-family: 'Font Awesome 6 Solid Sharp';
	src:  url('../fonts/fontawesome/fa-sharp-solid-900.ttf?hu3sw3') format('truetype'),
		  url('../fonts/fontawesome/fa-sharp-solid-900.woff2?hu3sw3') format('woff');
	font-weight: 900;
	font-style: normal;
}
