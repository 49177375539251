.am-license-info {
    .am-license-info-inner {}

    .am-license-section-title {
        h4 {
            margin: 0 0 15px;
            padding: 10px 0;
            font-size: 1.35rem;
            border-bottom: 1px solid rgba($color-black,0.20);
            }
    }

    .am-license-info-output {
        ul {
            &.am-license-info-list {
                li {
                    &.am-license-info-block {
                        padding-bottom: 15px;

                        .am-license-info-block-inner {
                            border: 1px solid rgba($color-black,0.15);
                            background-color: $color-white;

                            @include rounded(4px);
                            @include boxshadow(0 0 5px rgba($color-black,0.05));

                            .am-license-info-block-title {
                                padding: 8px 15px;
                                display: block;
                                color: $color-white;
                                font-size: 1.15rem;
                                font-weight: 500;
                                text-align: center;
                                text-overflow: ellipsis;
                                white-space: pre;
                                overflow: hidden;
                                border-bottom: 1px solid rgba($color-black,0.15);
                                background-color: #4f4f51;

                                @include rounded(4px 4px 0 0);
                                }

                            .am-license-info-block-subtext-1,
                            .am-license-info-block-subtext-2 {
                                padding: 15px;
                                text-align: center;

                                span {
                                    padding: 5px 0;
                                    font-size: 1.25rem;
                                    font-weight: 400;

                                    &.prop {
                                        position: absolute;
                                        top: -1px;
                                        left: -1px;
                                        margin: -1px 0 0 -1px;
                                        display: block;
                                        width: 1px;
                                        height: 1px;
                                        overflow: hidden;
                                        visibility: hidden;

                                        @include opacity(0.000001);
                                        }
                                    &.val {}
                                }
                            }
                        }

                        &.license-type {
                            .am-license-info-block-inner {}

                            .am-license-info-block-title {}
                            .am-license-info-block-subtext-2,
                            .am-license-info-block-subtext-1 {
                                padding: 17px 15px;
                                font-size: 1.25rem;
                                font-weight: 400;
                                }
                        }

                        &.license-renewal-date {
                            .am-license-info-block-inner {}

                            .am-license-info-block-title {}
                            .am-license-info-block-subtext-2 {
                                span {
                                    &.val {
                                        padding: 27px 5px;
                                        display: block;
                                        font-size: 1.25rem;
                                        font-weight: 400;
                                        }
                                }
                            }

                        }

                        &.col-20-percent { width: 19.99995%; }
                    }
                }
            }
        }
    }

    /* Manage Licenses Button */
    .am-manage-licenses {
        a {
            &.btn-am-add-licenses {
                display: block;
                width: 100%;
                max-width: 350px;
                color: #333;
                border: 1px solid rgba($color-black,0.15);
                background-color: $color-white;

                @include rounded(4px);
                @include boxshadow(0 0 5px rgba($color-black,0.05));
                @include transition(all 0.15s ease-in-out);

                span {
                    display: block;

                    &.btn-am-add-licenses-inner {
                        position: relative;
                        padding: 10px 10px 10px 100px;
                        min-height: 100px;
                        }

                    &.btn-am-add-licenses-image {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        display: block;
                        width: 80px;
                        height: 80px;
                        background: rgba($color-black,0.05) url("../images/tmb_account_management_licenses.png") center center no-repeat;
                        background-size: contain;
                        }

                    &.btn-am-add-licenses-content {}

                    &.btn-am-add-licenses-title {
                        padding: 15px 0 0;
                        font-size: 1.15rem;
                        font-weight: bold;
                        font-weight: 600;

                        @include transition(all 0.15s ease-in-out);
                        }

                    &.btn-am-add-licenses-desc {}
                }

                /* Hover State */
                &:hover {
                    border-color: #76bc43;

                    @include boxshadow(0 0 10px rgba(#76bc43,0.10));

                    span {
                        &.btn-am-add-licenses-title {
                            color: #76bc43;
                            }
                    }
                }
            }
        }
    }

    /* Support Button */
    .am-support {

        .am-support-button-wrapper {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid rgba($color-black, 0.15);
            }

        a {
            &.btn-am-support {
                display: block;
                width: 100%;
                max-width: 350px;
                color: #333;
                border: 1px solid rgba($color-black,0.15);
                background-color: $color-white;

                @include rounded(4px);
                @include boxshadow(0 0 5px rgba($color-black,0.05));
                @include transition(all 0.15s ease-in-out);

                span {
                    display: block;

                    &.btn-am-support-inner {
                        position: relative;
                        padding: 10px 10px 10px 100px;
                        min-height: 100px;
                        }

                    &.btn-am-support-image {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        display: block;
                        width: 80px;
                        height: 80px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        }

                    &.btn-am-support-content {}

                    &.btn-am-support-title {
                        padding: 15px 0 0;
                        font-size: 1.15rem;
                        font-weight: bold;
                        font-weight: 600;

                        @include transition(all 0.15s ease-in-out);
                        }

                    &.btn-am-add-licenses-desc {}
                }

                /* Hover State */
                &:hover {
                    border-color: #76bc43;

                    @include boxshadow(0 0 10px rgba(#76bc43,0.10));

                    span {
                        &.btn-am-support-title {
                            color: #76bc43;
                            }
                    }
                }
            }
        }
    }
}

/* Media Queries */
@media screen and (max-width: 1100px){
    .am-license-info {
        .am-license-info-output {
            ul {
                &.am-license-info-list {
                    li {
                        &.am-license-info-block {
                            .am-license-info-block-inner {}

                            &.col-3,
                            &.col-20-percent {
                                width: 50%;
                                }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .am-license-info {
        .am-license-info-output {
            ul {
                &.am-license-info-list {
                    li {
                        &.am-license-info-block {
                            .am-license-info-block-inner {}

                            &.col-3,
                            &.col-20-percent {
                                width: 100%;
                                }
                        }
                    }
                }
            }
        }

        .am-manage-licenses {
            --bs-gutter-x: 0;
            margin: 0 auto;
            display: block;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 400px){
    .am-license-info {
        .am-r1-footer-support {
            .am-r1-footer-inner {
                .r1-logo-col {
                    margin: 0 auto;
                    float: none;
                    }
                .contact-support {
                    margin-left: 0;
                    float: none;

                    a {
                        &.btn {
                            margin-right: 0;
                            display: block;
                            width: 100%;
                            }
                    }
                }
            }
        }
    }
}
