.mt-card-avatar {
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    overflow: hidden;
    position: relative;
    text-align: center;
    max-width: 200px;

    img {
        display: block;
        position: relative;
        -webkit-transition: all .4s linear;
        transition: all .4s linear;
        width: 100%;
        height: auto;
    }

    .mt-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.7);
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
        cursor: pointer;

        > span {
            color: #fff;
            position: absolute;
            top: 40%;
            font-size: 20px;
        }
    }

    &:hover {
        .mt-overlay {
            opacity: 1;
            filter: alpha(opacity=100);
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
        }

        img {
            -ms-transform: scale(1.2) translateZ(0);
            -webkit-transform: scale(1.2) translateZ(0);
            transform: scale(1.2) translateZ(0);
        }
    }
}

.mt-card-avatar-circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    .mt-overlay {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }
}
