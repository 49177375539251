html, body {
    width: 100%;
    height: 100%;
    }

body {
    margin: 0;
    padding: 0;
    /*font-family: $font1;*/
    }

/*ol, ul {
    list-style:  none;
}*/

*,*::before,*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    }


.pace {
    &.pace-active {
        display: block;
        }
    &.pace-inactive {
        display: none;
        }

    .pace-progress {
        position: fixed;
        top: 0;
        right: 100%;
        z-index: 10005;
        width: 100%;
        height: 2px;
        background: $color-green;

        .pace-progress-inner {
            position: absolute;
            right: 0;
            display: block;
            width: 100px;
            height: 100%;

            @include boxshadow(0 0 10px $color-green,0 0 5px $color-green);
            @include opacity(1);
            @include transform(rotate(3deg) translateY(-4px));
            }
    }

    .pace-activity {
        position: fixed;
        top: 20px;
        left: 50%;
        z-index: 2000;
        display: block;
        width: 14px;
        height: 14px;
        border-width: 2px;
        border-style: solid;
        border-color: $color-green transparent transparent $color-green;

        @include transform(translateX(-50%));
        /* @include animation(pace-spinner 0.4s linear infinite); */
        -webkit-animation: pace-spinner .4s linear infinite;
        animation: pace-spinner .4s linear infinite;
        @include rounded(10px);
        }
}

body {
    #app {

        /* Header */
        .navbar {
            padding: 0 15px;

            .navbar-inner {
                width: 100%;
                max-width: 1375px;
                }
        }
    }

    /* Authentication Page */
    &.authentication-body {
        #app {
            .navbar {
                .navbar-inner {
                    margin: 0 auto;
                    }
            }

            .profile-main {
                .profile-content {
                    margin: 0 auto;
                    }
            }
        }
    }

    /* Profile Content */
    &.profile-content-body {

        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            margin-right: 0;
            margin-left: 0;
            max-width: 1400px;
            }

        #app {
            height: 100%;
            overflow: hidden;
            background-color: #efefef;

            /* Profile Content */
            .profile-main {
                height: 100%;

                /* Sidebar */
                .profile-sidebar {}

                /* Content */
                .profile-content {
                    width: 100%;
                    height: 100%;

                    .profile-content-outer {
                        position: relative;
                        padding-top: 58px;
                        height: 100%;

                        .navbar {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            width: 100%;
                            }
                    }

                    .profile-content-inner {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        height: 100%;
                        overflow: auto;
                        overflow-x: hidden;
                        overflow-y: auto;
                        }
                }

                /* Sidebar */
                .profile-sidebar {
                    + .profile-content {
                        margin: 0 0 0 -250px;
                        padding: 0 0 0 250px;
                        float: left;
                        }
                }

                /* Global Dashboard Form Frame */
                .main-dashboard-form {
                    padding: 20px;
                    background-color: $color-white;

                    box-shadow: rgb(204, 204, 204) 0 1px 1px;
                    @include boxshadow(0 0 0 rgba(0, 0, 0, 0.00));
                    @include rounded(5px);

                    /* Collapse Padding */
                    &.collapse-padding {
                        padding: 0 !important;
                        }

                    /* Transparent Background Color */
                    &.transparent-background {
                        background-color: transparent !important;
                        }
                }

                /* Page Header */
                .page-header {
                    margin-bottom: 20px;

                    .page-header-inner {
                        h4 {
                            margin: 0;
                            padding: 0;
                            display: block;
                            float: left;
                            }

                        ul {
                            &.actions {
                                float: right;

                                > li {
                                    &.action-item {
                                        padding: 0 0 0 10px;
                                        display: block;
                                        float: left;

                                        &:first-child { padding-left: 0; }

                                        a {
                                            @include transition(all 0.10s ease-in-out);

                                            /* Hover State */
                                            &:hover {
                                                @include opacity(0.70);
                                                }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* Dashboard Page Form Frame */
                .dashboard {
                    .main-dashboard-form {
                        padding: 10px;
                        }
                }

            }
        }
    }

    /* Topics Grid */
    .available-topics-color {
        .available-topics-color-inner {
            display: inline-block;
            width: 20px;
            height: 20px;
            }
    }
}

/* Media Queries */
/* 800px */
@media screen and (max-width: 800px){
    body {
        &.profile-content-body {
            background-color: #efefef;

            #app {
                overflow: auto;
                overflow-x: hidden;
                overflow-y: auto;
                background-color: transparent;

                .profile-main {
                    min-height: 0;
                    max-height: none;

                    .profile-sidebar {
                        + .profile-content {
                            margin-left: 0;
                            padding-left: 0;
                            float: none;
                            height: auto;

                            .profile-content-outer {
                                padding-top: 0;
                                height: auto;

                                .navbar {
                                    position: relative;
                                    top: auto;
                                    left: auto;
                                    }
                            }

                            .profile-content-inner {
                                width: 100%;
                                }
                        }
                    }
                }
            }
        }
    }
}
