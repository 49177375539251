.spinner-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    padding: 20px 0 10px;
    display: block;
    width: 100px;
    text-align: center;
    border: 1px solid rgba($color-black,0.10);
    background-color: rgba($color-white,0.30);

    @include rounded(3px);
    @include boxshadow(0 0 10px rgba($color-black,0.05));
    @include transform(translate(-50%, -50%));

    i {
        font-size: 1.35rem;
        }
    .spinner-loader-text {
        padding: 10px 0 0;
        display: block;
        text-align: center;
        }
}


.spinner-loader-2 {
    padding: 20px 0 10px;
    display: block;
    width: 100px;
    text-align: center;
    border: 1px solid rgba($color-black,0.10);
    background-color: rgba($color-white,0.30);

    @include rounded(3px);
    @include boxshadow(0 0 10px rgba($color-black,0.05));

    i {
        font-size: 1.35rem;
    }
    .spinner-loader-text {
        padding: 10px 0 0;
        display: block;
        text-align: center;
    }
}