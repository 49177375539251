

/* Sidebar Toggle */
#app {
    .profile-main {
        button {
            &.nav-toggler-sidebar {
                position: fixed;
                top: 125px;
                left: 0;
                z-index: 99999;
                margin: 0;
                padding: 0;
                display: none;
                width: 25px;
                height: 48px;
                color: $color-white;
                font-size: 1.15rem;
                line-height: 46px;
                text-align: center;
                border: 1px solid rgba($color-black,0.10);
                border-width: 1px 1px 1px 0;
                background-color: $color-charcoal;

                @include rounded(0 3px 3px 0);
                @include transition(
                    border-color 0.10s ease-in-out,
                    background-color 0.10s ease-in-out
                );

                /* Active/Hover States */
                &.active,
                &:hover {
                    border-color: $color-green;
                    background-color: $color-green;
                    }
            }
        }
    }
}

/* Media Queries */
/* 800px */
@media screen and (max-width: 800px){
    #app {
        .profile-main {
            button {
                &.nav-toggler-sidebar {
                    display: block;
                    }
            }
        }
    }
}



/* Sidebar */
.profile-sidebar {
    position: relative;
    z-index: 2;
    float: left;
    width: 250px;
    height: 100%;
    background-color: $color-charcoal;

    /* Mobile Sidebar Close */
    button {
        &.nav-toggler-sidebar-close {
            position: absolute;
            top: 8px;
            right: 15px;
            z-index: 1;
            display: none;
            width: 30px;
            height: 30px;
            color: $color-white;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
            border: 1px solid transparent;
            background-color: transparent;

            @include rounded(3px);

            /* Hover State */
            &:hover {
                color: $color-green;
                background-color: rgba($color-black,0.20);
                }
        }
    }

    .profile-sidebar-inner {
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
        }

    h4 {
        margin: 0;
        padding: 15px;
        display: block;
        color: $color-white;
        font-size: 0.80rem;
        text-transform: uppercase;
        background-color: $color-gray-3;
        }

    .sidebar-profile {
        padding: 0 0 15px;

        h4 {
            background-color: transparent;
            }

        ul {
            &.profile-info-list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    position: relative;
                    margin: 0;
                    padding: 5px 15px;
                    display: block;
                    color: $color-white;

                    a {
                        color: $color-white;
                        text-decoration: none;

                        @include transition(color 0.10s ease-in-out);
                        }

                    /* Hover State */
                    &:hover {
                        a {
                            color: #4d97c1;
                            }
                    }
                }
            }
        }
    }

    /* Sidebar Navigation */
    .sidebar-navigation {
        > ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            > li {
                position: relative;
                margin: 0;
                padding: 0;
                display: block;

                > a {
                    position: relative;
                    padding: 15px 15px 15px 45px;
                    display: block;
                    color: $color-white;
                    color: rgba($color-white,0.65);
                    text-decoration: none;

                    @include transition(
                        color 0.10s ease-in-out,
                        background-color 0.10s ease-in-out
                    );

                    /* Icons */
                    &::before {
                        position: absolute;
                        top: 13px;
                        left: 15px;
                        display: block;
                        width: 24px;
                        height: 24px;
                        font-family: $faregular;
                        line-height: 24px;
                        text-align: center;
                        }

                    > span {
                        &.link-title {
                            width: 80%;
                            display: inline-block;
                        }

                        &.expand-submenu {
                            width: 15%;
                            display: inline-block;
                        }
                    }
                }

                /* Icon: Account Dashboard */
                &.account-dashboard {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/monitor-waveform?s=regular */
                            content: '\f611';
                            }
                    }
                }

                /* Icon: Account Management */
                &.account_management {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/people-roof?f=classic&s=regular */
                            content: '\e537';
                        }
                    }
                }

                /* Icon: Account Security */
                &.account-security {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/shield-alt?style=regular */
                            content: '\f3ed';
                            }
                    }
                }

                /* Icon: API Keys */
                &.account-api.item {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/webhook?f=classic&s=regular */
                            content: '\e5d5';
                        }
                    }
                }

                /* Icon: API Docs */
                &.account-api-docs.item {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/book?f=classic&s=regular */
                            content: '\f02d';
                        }
                    }
                }

                /* Icon: Settings */
                &.Settings {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/cog?style=regular */
                            content: '\f013';
                            }
                    }
                }

                /* Icon: Companies */
                &.company-info {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/archway?style=regular */
                            content: '\f557';
                            }
                    }
                }

                /* Icon: My Practices */
                &.my-practices {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/archway?style=regular */
                            content: '\f557';
                            }
                    }
                }

                /* Icon: Facility */
                &.facility {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/archway?style=regular */
                            content: '\f557';
                            }
                    }
                }

                /* Icon: Practitioner */
                &.practice {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/hotel?style=regular*/
                            content: '\f594';
                            }
                    }
                }

                /* Icon: User */
                &.User {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/users?style=regular */
                            content: '\f0c0';
                            }
                    }
                }

                /* Icon: Topics */
                &.topics {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/object-group?style=regular */
                            content: '\f247';
                            }
                    }
                }

                /* Icon: Videos */
                &.videos {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/object-group?style=regular */
                            content: '\f03d';
                            }
                    }
                }

                /* Icon: Facility Administrators */
                &.Facility {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/users-class?style=regular */
                            content: '\f63d';
                            }
                    }
                }

                /* Icon: Practice Administrators */
                &.Practice, &.Practitioner, &.employees {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/users-cog?style=regular */
                            content: '\f509';
                            }
                    }
                }

                /* Icon: Programs */
                &.programs, &.Programs {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/code-branch?style=regular */
                            content: '\f126';
                            }
                    }
                }

                /* Icon: Program Mapping */
                &.program_mapping {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/map-location?s=regular */
                            content: '\f59f';
                            }
                    }
                }

                /* Icon: Member Activities */
                &.my-activities,
                &.member-activities {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/chart-network?style=regular */
                            content: '\f78a';
                            }
                    }
                }

                /* Icon: Company Dashboard */
                &.member-company-dashboard {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/line-columns?s=regular */
                            content: '\f870';
                            }
                    }
                }

                /* Icon: My Activities */
                &.my-activities {
                    > a {
                        &::before {

                            /* https://fontawesome.com/v5.15/icons/skating?style=regular */
                            content: '\f7c5';
                            }
                    }
                }

                /* Icon: Account Privacy */
                &.account-privacy {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/lock-keyhole?s=regular */
                            content: '\f30d';
                            }
                    }
                }

                /* Icon: SSO Configuration */
                &.sso-configuration {
                    > a {
                        &::before {

                            /* https://fontawesome.com/icons/infinity?s=regular */
                            content: '\f534';
                            }
                    }
                }

                /* Hover and Active States */
                &:hover,
                &.active,
                &.expanded {
                    > a {
                        color: $color-white;
                        text-decoration: none;
                        }
                }

                &.active {
                    &.has-child-menu {
                        > a {
                            color: $color-green;
                            font-weight: bold;
                        }
                    }
                    &:not(.has-child-menu) {
                        > a {
                            background-color: $color-green;
                        }
                    }
                }

                ul {
                    display: none;
                    background-color: $color-gray-f;

                    > li {
                        display: block;

                        > a {
                            padding: 15px 15px 15px 45px;
                            display: block;
                            color: $color-white;
                            }

                        /* Hover and Active States */
                        &:hover {
                            > a {
                                color: $color-white;
                                background-color: rgba($color-green, 0.75);
                            }
                        }
                        &.active {
                            > a {
                                color: $color-white;
                                background-color: rgba($color-green, 0.75);
                                font-weight: bold;
                                }
                        }
                    }
                }

                /* Expanded State */
                &.expanded {
                    > a {
                        color: $color-white;
                        text-decoration: none;
                        font-weight: bold;
                            > span {
                                &.expand-submenu {
                                    @include transform(rotate(180deg));
                                }
                            }
                        }

                    > ul {
                        display: block;
                        }
                }
            }
        }
    }
}

/* Media Queries */
@media screen and (max-width: 800px){
    .profile-sidebar {
        position: fixed;
        top: 0;
        left: -250px;
        z-index: 999999;
        height: 100%;
        background-color: $color-charcoal;

        @include opacity(0.0000001);
        @include transition(
            left 0.20s ease-in-out,
            opacity 0.20s ease-in-out
        );

        /* Mobile Sidebar Close */
        button {
            &.nav-toggler-sidebar-close {
                display: block;
                }
        }
    }

    button {
        &.nav-toggler-sidebar {

            /* Active State */
            &.active {
                + {
                    .profile-sidebar {
                        left: 0;

                        @include opacity(1.00);
                        }
                }
            }
        }
    }
}
