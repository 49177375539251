.form-control {
    border: 1px solid $gray-light-color;

    @include boxshadow(inset 0 1px 2px rgba(27,31,35,0.075));

    /* Focus State */
    &:focus {
        @include boxshadow(none);
        }
}

.navbar-light {
    .brand-container {
        width: 34px;
        height: 34px;
        background-color: #22292f;
        }

    .navbar-brand {
        color: $color-white;
        font-size: 22px;

        /* Hover/Focus States */
        &:hover,
        &:focus {
            color: $color-white;
            }
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='#22292f' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
        }

    .navbar-toggler {
        border: 0;
        outline: none;
        }
}
