body {
    &.login {
        background-color: $color-white;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        -webkit-background-size: cover;
        overflow: hidden;

        .logo-title-container {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;

            .copy {
                padding: 30px 30px 12px;
                width: auto;

                &.animated {
                    -webkit-animation-duration: 1s;
                    animation-duration: 1s;
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;

                    &.fadeIn {
                        -webkit-animation-name: fadeIn;
                        animation-name: fadeIn;
                        }
                }
            }
        }

        .login-sidebar {
            position: relative;
            z-index: 2;
            padding: 0;
            justify-content: center;
            min-height: 100vh;
            border-top: 5px solid #02192d;
            background: $color-white;

            @include rounded(0);
            }

        .login-container {
            position: absolute;
            top: 50%;
            z-index: 10;
            margin-top: -150px;
            padding: 30px;
            width: 100%;

            p {
                margin-top: 3px;
                margin-bottom: 0;
                padding-left: 2px;
                width: auto;
                color: #757c85;
                font-size: 10px;
                font-weight: 700;
                text-align: left;
                text-transform: uppercase;

                @include rounded(2px);
                }

            form {
                padding-top: 15px;
                }
        }

        .form-group {
            .form-control {
                margin: 0;
                margin-top: -4px;
                padding: 0;
                display: block;
                width: 100%;
                height: 25px;
                min-height: 25px;
                color: #1c2b36;
                font-size: .9rem;
                font-weight: 400;
                line-height: normal;
                vertical-align: middle;
                outline: 0;
                border: none;
                background: $color-white;
                background: 0 0;

                -webkit-appearance: none;
                @include rounded(0.25rem);
                @include boxshadow(none);
                @include transition(
                    box-shadow .25s linear,
                    border .25s linear,
                    color .25s linear,
                    background-color .25s linear
                );
            }
        }

        .login-button,
        .btn-submit,
        #resend-code {
            padding: 12px 20px;
            display: block;
            float: left;
            width: auto;
            color: #eee !important;
            font-size: 11px;
            font-weight: 400;
            text-align: center;
            text-transform: uppercase;
            outline: 0 !important;
            border: 0;
            background-color: #02192d;

            @include transition(width .3s ease);

            @include rounded(2px);
            @include opacity(0.80);

            /* Hover State */
            &:hover {
                color: $color-white;

                @include opacity(1.00);
                }
        }

        #email_code {
            height: 40px;
            border: 1px solid #bbb;
            background-color: #fff;
            }

        .form-outer {
            &.email-code-2fa-form {
                &.admin-2fa-form {
                    .btn-submit,
                    #resend-code {
                        margin-right: 10px;
                        }
                }
            }
        }
    }
}


